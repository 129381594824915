<template>
  <div class="intro-main">
    <v-flex lg6  sm6 xs6>
      <div class="intropanel">
        <div v-if="loading" class="text-center justify-center align-center loadingpanel">
          <v-progress-circular classclass="text-center justify-center align-center loadingpanel" indeterminate color="deep-orange" width="2" size="75"></v-progress-circular>
          <p class="text-center mt-4">Uploading...</p>
        </div>
        <div class="intropanel-content" v-if="!loading">
          <input
            hidden
            type="file"
            accept="image/*"
            ref="imageupload"
            v-on:change="upload($event.target.files)"
          />
          <img v-if="!selected.image" class="imgintro" src="../assets/onboarding1.png" alt="onboard">
          <img v-if="selected.image" class="imgintro" :src="selected.image" alt="onboard">

          <h2 class="text-center intro-title">{{selected.title}}</h2>
          <p class="text-center intro-description">{{selected.description}}</p>
          <div class="intro-action">
            <div>Skip</div>
            <div class="text--accent-2">Next</div>
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex lg6  sm6 xs6>
      <div class="intro-editor">
        <v-text-field v-model="selected.title" label="Title"></v-text-field>
        <v-textarea
          v-model="selected.description"
          rows="3"
          label="Description">
        </v-textarea>
        <button @click="openfile" class="btnintro">Upload Image</button>
        <v-switch
          flat icon class="ml-4"
          inset
          v-model="selected.status"
          label="Status"/>
      </div>
    </v-flex>
  </div>
</template>

<script>
import {serviceUpload} from "@/services/serviceupload";
import {mapActions, mapState} from "vuex";

export default {
  name: "IntroPanel",
  data:() => ({
    loading:false,
    imgurl: '',
    src: '',
  }),
  computed: {
    ...mapState('IntroStore', ['selected'])
  },
  methods: {
    ...mapActions('IntroStore', ['setIimageselected', 'addIntro']),
    showPreview: async function (file) {
      var reader = new FileReader()
      if (file) {
        this.loading = true
        console.log('uploading')
       let resp =  await serviceUpload.newuploadimage(file);
       this.imgurl = resp.data;
       this.setIimageselected(resp.data)
       this.$emit('upload',resp.data);
        reader.readAsDataURL(file)
        this.src = await new Promise((resolve) => {
          reader.onload = function () {
            resolve(reader.result)
          }
        })
      }
      this.loading = false
    },
    openfile () {
      this.$refs.imageupload.click()
    },
    upload: function (files) {
      this.showPreview(files[0])
    }
  }
}
</script>

<style scoped>
.loadingpanel {
  position:absolute;
  top: 100px;
  left: 60px;
  text-align:center;
  align-items: center;
  padding-right: 8px;
}
.btnintro {
  background-color: cadetblue;
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  color: white;
}
.intro-editor {
  padding-left: 8px;
  padding-right: 8px;
}
.intro-main {
  display: flex;
  margin-top: 8px;
  justify-content: center;
}
.intropanel {
  overflow: hidden;
  border: thin solid rgba(0,0,0,.12);
  height: 400px;
  padding: 8px;
  margin-top: 10px;
  position: relative;
  border-radius: 4px;
}
.intro-title {
  font-size: 20px;
  margin-top: 50px;
}
.intropanel-content {
  position:absolute;
  bottom: 0;
  text-align: center;
  align-items: center;
  padding-right: 8px;
}
.intro-description {
  font-size: 14px;
}
.intro-action {
  display: flex;
  justify-content: space-between;
  color: #79A70A;
  align-items: center;
  height: 50px;
  padding-right: 8px;
  font-size: 14px;
}
.imgintro {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px
}
</style>
