var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 pr-0 pl-0 pt-2 border_all",staticStyle:{"border":"thin solid rgba(0,0,0,.12)"}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-text-field',{staticClass:"hidden-sm-and-down",attrs:{"flat":"","solo":"","prepend-icon":"mdi-text-search","placeholder":"Type something","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{staticClass:"mt-6"},[_c('v-select',{attrs:{"label":"Status","item-text":"label","item-value":"id","items":_vm.statuses},model:{value:(_vm.opsitable.status),callback:function ($$v) {_vm.$set(_vm.opsitable, "status", $$v)},expression:"opsitable.status"}})],1),_c('div',{staticClass:"mt-6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Awal","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.opsitable.awal),callback:function ($$v) {_vm.$set(_vm.opsitable, "awal", $$v)},expression:"opsitable.awal"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.opsitable.awal),callback:function ($$v) {_vm.$set(_vm.opsitable, "awal", $$v)},expression:"opsitable.awal"}})],1)],1),_c('div',{staticClass:"mt-6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Akhir","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.opsitable.akhir),callback:function ($$v) {_vm.$set(_vm.opsitable, "akhir", $$v)},expression:"opsitable.akhir"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.opsitable.akhir),callback:function ($$v) {_vm.$set(_vm.opsitable, "akhir", $$v)},expression:"opsitable.akhir"}})],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","elevation":"0","color":"primary"},nativeOn:{"click":function($event){return _vm.getListQrpayment(_vm.opsitable)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")]),_vm._v("Refresh ")],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.qrpayment,"item-key":"id","options":_vm.opsitable,"server-items-length":_vm.totalqr,"loading":_vm.loading},on:{"update:options":[function($event){_vm.opsitable=$event},function($event){return _vm.getListQrpayment(_vm.opsitable)}]},scopedSlots:_vm._u([{key:"item.reseller",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reseller.idreseller+ '- ' +item.reseller.namareseller)+" ")]}},{key:"item.tumb",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_c('v-img',{attrs:{"max-height":"100","max-width":"100","src":item.tumb}})],1)]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_c('v-img',{attrs:{"max-height":"200","max-width":"200","src":item.image}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-4 text-xs-left"},[_c('v-chip',{attrs:{"color":_vm.colorStatus(item.status),"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.statusText(item.status))+" ")])],1)]}},{key:"item.updateat",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(_vm.formatDate(item.updateat))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-4 text-xs-left"},[_c('v-btn',{staticClass:"primary",attrs:{"x-small":"","fab":"","text":""},nativeOn:{"click":function($event){return _vm.openedit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }