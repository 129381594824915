import { PaymentGatewayService } from '@/services/paymentgateway.service'

const state = {
  loading: false,
  dialog: false,
  dialogalfa: false,
  overlay: false,
  va: [],
  alfamart: [],
  qrpayment: [],
  totalva: 0,
  totalalfamart:0,
  totalqr:0
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  setdialogalfa ({ commit }, diag) {
    commit('setdialogalfa', diag)
  },
  getListVa({dispatch, commit },opt) {
    commit('setloading',true)
    PaymentGatewayService.getListva(opt).then(value => {
      commit('setdatava',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  updateVA({ dispatch,commit },opt) {
    commit('setloading',true)
    PaymentGatewayService.updateVA(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getListAlfaMart({dispatch, commit },opt) {
    commit('setloading',true)
    PaymentGatewayService.getListAlfamart(opt).then(value => {
      commit('setdataalfa',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  updateAlfacode({ dispatch,commit },opt) {
    commit('setloading',true)
    PaymentGatewayService.updateAlfacode(opt).then(value => {
      commit('setloading',false)
      commit('setdialogalfa',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getListQrpayment({dispatch, commit },opt) {
    commit('setloading',true)
    PaymentGatewayService.getListQr(opt).then(value => {
      commit('setdataqr',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdialogalfa (state, s) {
    state.dialogalfa = s
  },
  setdatava (state, s) {
    state.va = []
    state.va = s.data.content
    state.totalva = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setdataalfa (state, s) {
    state.alfamart = []
    state.alfamart = s.data.content
    state.totalalfamart = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setdataqr (state, s) {
    state.qrpayment = []
    state.qrpayment = s.data.content
    state.totalqr = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
}

export const PaymentGatewayStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
