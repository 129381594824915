
import {InfoRekeningService} from "@/services/inforekeningservice";

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  inforekening: [],
  totalinforekening: 0
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getInfoRekening({dispatch, commit },opt) {
    commit('setloading',true)
    InfoRekeningService.getInfoRekening(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addInfoRekening({ dispatch,commit },opt) {
    commit('setloading',true)
    InfoRekeningService.addInfoRekening(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getInfoRekening')
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editInfoRekening({ dispatch,commit },opt) {
    commit('setloading',true)
    InfoRekeningService.updateInfoRekening(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getInfoRekening')
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteInfoRekening({ dispatch,commit },opt) {
    commit('setloading',true)
    InfoRekeningService.deleteInfoRekening(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getInfoRekening')
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.inforekening = []
    state.inforekening = s.data.content
    state.totalinforekening = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const InfoRekeningStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
