import { PushNotifService } from '@/services/push.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  kodepromo: [],
  totalkodepromo: 0
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  sendbcpush({ dispatch,commit },opt) {
    commit('setloading',true)
    PushNotifService.sendBcPush(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.kodepromo = []
    state.kodepromo = s.data.content
    state.totalkodepromo = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const PushStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
