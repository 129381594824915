<template>
  <v-menu v-if="isdalamstore" offset-y origin="center center" :nudge-bottom="10"  transition="scale-transition">
    <template v-slot:activator="{ on }">
      <v-chip slot="activator" v-on="on"
              class="ma-2"
              color="primary"
              text-color="white">
        Rp.{{formatAngka(totalamount)}} ({{cart.length}} items)
        <v-icon right class="mr-1">
          mdi-cart
        </v-icon>
      </v-chip>
    </template>
    <v-card v-if="cart.length>0" elevation="1" width="300">
      <v-list dense class="pa-0">
        <template v-for="(item, index) in cart">
          <v-list-item :key="index">
            <v-list-item-avatar><v-img :src="item.preview"></v-img> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.nama }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <div class="pa-2">{{formatAngka(item.price)}}</div>
               <v-btn @click="deleteItemCart(index)" icon> <v-icon>mdi-delete</v-icon></v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>

        <v-btn @click="checkout" small text dark class="green">Bayar</v-btn>
        <v-spacer/>
        <v-btn small @click="clearchart" text dark class="red">Clear</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import menu from '@/api/menu'
import Utils from '@/plugins/utils'

export default {
  name: 'BtnShoppingCart',
  computed: {
    ...mapState('IRSXStore', ['cart']),
    isdalamstore: function (){
      return this.$route.path.includes("irsxstore")
    },
    totalamount: function(){
      let tot=0
      for (let i = 0; i < this.cart.length; i++) {
        tot +=this.cart[i].price
      }
      return tot;
    },
    breadcrumbs: function () {
      const breadcrumbs = []
      menu.forEach(item => {
        if (item.items) {
          const child = item.items.find(i => {
            return i.component === this.$route.name
          })
          if (child) {
            breadcrumbs.push(item.title)
            breadcrumbs.push(child.title)
            this.title = child.title
          }
        } else {
          if (item.name === this.$route.name) {
            this.title = item.title
            breadcrumbs.push(item.title)
          }
        }
      })
      return breadcrumbs
    }
  },
  methods: {
    ...mapActions('IRSXStore', ['getCategories','getProducts','clearchart', 'deleteItemCart','setdialogneworder']),
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    checkout(){
      this.setdialogneworder(true);
    },
    refresh() {
      if(this.$route.path.includes("irsxstore")){
        console.log('refresh IRSStore');
        this.getProducts();
      }
    }
  }
}
</script>

<style scoped>

</style>
