import router from '../router/'
import { AuthService } from '@/services/auth'
import store from '../store/index'

const state = {
  status: { loggedIn: true },
  token: null,
  acterror: false,
  acterrormsg: 'token exp',
  loading: false,
  drawer: true,
  user: {
    username: '',
    email: '',
    image: '',
    roles: []
  },
  isAdmin: false,
  admintersedia:true
}
const actions = {
  stateDrawer ({ commit }) {
    commit('getDrawerstatex')
  },
  cekadauser ({ dispatch, commit }) {
    commit('setloading', true)
    AuthService.getconfig().then(data => {
        commit('setdataconfig', data)
        commit('setloading', false)
      })
      .catch(error => {
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  registerAdm ({ dispatch, commit }, regdata) {
    commit('setloading', true)
    AuthService.regAdmin(regdata)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'registerOK', { root: true })
      })
      .catch(error => {
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  register ({ dispatch, commit }, regdata) {
    commit('setloading', true)
    AuthService.registerUser(regdata)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'registerOK', { root: true })
      })
      .catch(error => {
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  activate ({ dispatch, commit }, token) {
    commit('setloading', true)
    AuthService.activateUser(token)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'User activated successfully', { root: true })
      })
      .catch(error => {
        commit('setacterr', error)
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  setpass ({ dispatch, commit }, set) {
    commit('setloading', true)
    AuthService.gantipasswordsetpass(set)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'reset password succfully', { root: true })
      })
      .catch(error => {
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  resetpass ({ dispatch, commit }, email) {
    commit('setloading', true)
    AuthService.forgotpassword(email)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'Reset password link terkirim ke email anda', { root: true })
      })
      .catch(error => {
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  gantipassword ({ dispatch, commit }, { pass }) {
    commit('setloading', true)
    AuthService.gantipassword(pass)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'Ganti Password Successfully', { root: true })
      }).catch(error => {
        commit('setloading', false)
        dispatch('alert/error', error, { root: true })
      })
  },
  dologin ({ dispatch, commit }, { email, password }) {
    commit('setloading', true)
    AuthService.login(email, password).then(user => {
      commit('loginSuccess', user)
      dispatch('alert/success', 'Login Successfully', { root: true })
      //dispatch('UtilsStore/addfcmDashboard', fcm, { root: true })
      AuthService.getme().then(data => {
        commit('getme', data)
        //store.dispatch('setuser', data)
        //window.getApp.$emit('SOCKET_LOGIN', data)
        router.push('/dashboard')
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      console.log(error)
      commit('setloading', false)
      commit('loginFailure', error)
      dispatch('alert/error', error, { root: true })
    })
  },
  dologinGoogle ({ dispatch, commit }, idtoken) {
    commit('setloading', true)
    AuthService.googleauth(idtoken).then(user => {
      commit('loginSuccess', user)
      dispatch('alert/success', 'Login Successfully', { root: true })
      const fcm = localStorage.getItem('fcm')
      dispatch('UtilsStore/addfcmDashboard', fcm, { root: true })
      AuthService.getme().then(data => {
        commit('getme', data)
        store.dispatch('setuser', data)
        window.getApp.$emit('SOCKET_LOGIN', data)
        router.push('/dashboard')
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      console.log(error)
      commit('setloading', false)
      commit('loginFailure', error)
      dispatch('alert/error', error, { root: true })
    })
  },
  dologinFacebook ({ dispatch, commit }, token) {
    commit('setloading', true)
    console.log(token)
    AuthService.facebookauth(token).then(user => {
      commit('loginSuccess', user)
      dispatch('alert/success', 'Login Successfully', { root: true })
      const fcm = localStorage.getItem('fcm')
      dispatch('UtilsStore/addfcmDashboard', fcm, { root: true })
      AuthService.getme().then(data => {
        commit('getme', data)
        store.dispatch('setuser', data)
        window.getApp.$emit('SOCKET_LOGIN', data)
        router.push('/dashboard')
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      console.log(error)
      commit('setloading', false)
      commit('loginFailure', error)
      dispatch('alert/error', error, { root: true })
    })
  },

  getme ({ dispatch, commit }) {
    AuthService.getme()
      .then(data => {
        commit('getme', data)
      }).catch(error => {
        console.log('getme error')
        AuthService.logout()
        commit('loginFailure', error)
        dispatch('alert/error', error, { root: true })
        if (error.indexOf('invalid_token')) {
          router.push('/login')
        }
      })
  },

  logout () {
    AuthService.logout()
    router.push('/login')
  }
}
const mutations = {
  setdataconfig (state,confgadmin) {
    state.admintersedia = confgadmin.admintersedia;
  },
  getDrawerstatex (state) {
    state.drawer = !state.drawer
  },
  setacterr (state, msg) {
    state.acterror = true
    state.acterrormsg = msg
  },
  setloading (state, load) {
    state.loading = load
  },
  loginSuccess (state, user) {
    console.log(user.access_token)
    state.loading = false
    state.status = { loggedIn: true }
    // state.user = { id: user.id, name: user.name };
    state.token = user.token
    localStorage.setItem('token', user.access_token)
  },
  loginFailure (state) {
    state.loading = false
    state.status = {}
    state.user = { id: null, name: null }
    state.token = null
  },
  getme (state, user) {
    console.log('getMe:' + JSON.stringify(user.data))
    state.user = {
      id: user.data.id,
      name: user.data.name,
      info: user.data.info,
      username: user.data.username,
      menu: user.data.menu,
      email: user.data.email,
      roles: user.data.roles
    }
    localStorage.setItem('user', JSON.stringify(state.user))
    state.isAdmin = false
  }
}

export const authentication = {
  namespaced: true,
  state,
  actions,
  mutations
}
