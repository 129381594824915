<template>
  <div @click="openfile" class="imageupload upload-select-picture-card">
    <input
      hidden
      type="file"
      accept="image/*"
      ref="imageupload"
      v-on:change="upload($event.target.files)"
    />
    <div  v-if="loading" class="imageupload">
      <v-progress-circular
        :size="50"
        :width="2"
        class="imgupload"
        color="red"
        indeterminate
      ></v-progress-circular>
      <div class="upload-text">
        Uploading....
      </div>
    </div>
    <div class="imageupload">
      <img v-if="!value" alt="imgupload" width="100px" src="../assets/upload.png">
      <img v-if="!loading && value"
           class="imgupload"
           :src="value"
           alt="image"
           width="500"
           height="100"
      />
      <v-icon v-if="done && !loading">mdi-cloud-upload</v-icon>
      <div v-if="!done && !loading" class="upload-text">
        {{label}}
      </div>
    </div>
  </div>
</template>


<script>
import { serviceUpload } from '@/services/serviceupload'

export default {
  name: 'ImageNewsUpload',
  props: ['label', 'value'],
  data: () => ({
    loading: false,
    done: true,
    src: ''
  }),
  methods: {
    showPreview: async function (file) {
      var reader = new FileReader()
      if (file) {
        this.loading = true
        console.log('uploading')
        serviceUpload.newuploadimage(file)
          .then(response => {
            this.loading = false
            console.log(response.data)
            this.done = true
            window.getApp.$emit('DONE_SET_IMAGE_NEWS', response.data)
          })
          .catch(error => {
            this.loading = false
            this.done = false
            console.log('err upload')
            console.log(error)
          })
        reader.readAsDataURL(file)
        const result = await new Promise((resolve) => {
          reader.onload = function () {
            resolve(reader.result)
          }
        })
        this.src = result
        this.value = result
      }
    },
    openfile () {
      this.$refs.imageupload.click()
    },
    upload: function (files) {
      this.showPreview(files[0])
    }
  }
}
</script>

<style scoped>

.imgupload {

}

.upload-select-picture-card .upload-text {
  margin-top: 8px;
  color: #666;
}
.upload-select-picture-card {
  display: table;
  width: 95%;
  height: 125px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color .3s ease;
}
.imageupload {
  box-sizing: border-box;
  margin: 8px;
  padding: 8px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
}
.upload-select-picture-card > .imageupload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

</style>

