
import { RunningTextService } from '@/services/runningtext.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  runningtext: [],
  totalrunningtext: 0
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getRunningtext({dispatch, commit },opt) {
    commit('setloading',true)
    RunningTextService.getRunningtext(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addRunningtext({ dispatch,commit },opt) {
    commit('setloading',true)
    RunningTextService.addRunningText(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editRunningtext({ dispatch,commit },opt) {
    commit('setloading',true)
    RunningTextService.editRunningtext(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteRunningtext({ dispatch,commit },opt) {
    commit('setloading',true)
    RunningTextService.deleteRunningtext(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.runningtext = []
    state.runningtext = s.data.content
    state.totalrunningtext = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const RunningTextStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
