<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog scrollable v-model="dialog" persistent width="450">
      <v-card>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="text-xs-left pl-2 pr-2 pb-4">
          <IntroPanel v-on:upload="uploaded"></IntroPanel>
        </div>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="intros"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalintro"
      :loading="loading"
      @update:options="getAllIntro(opsitable)">

      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-text-field
            flat
            solo
            clearable
            prepend-icon="mdi-text-search"
            placeholder="Type something"
            v-model="opsitable.search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
            <v-icon dark>mdi-plus</v-icon>Tambah
          </v-btn>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getAllIntro(opsitable)">
            <v-icon dark>mdi-refresh</v-icon>Refresh
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.nourut="{ item }">
        <div class="" v-if="selected.id===item.id">
          <v-row>
            <v-text-field @click:append-outer="simpan" append-outer-icon="mdi-check" v-model="item.nourut"></v-text-field>
          </v-row>
        </div>
        <div @dblclick="clickedit(item)">{{item.nourut}}</div>

      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="pa-4">
          <v-img max-height="150" max-width="150" :src="item.image"/>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn x-small fab text @click.native="openedit(item)" class="primary">
          <v-icon small>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn icon x-small fab text @click.native="deleteconfm(item)" dark class="red">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import IntroPanel from "@/components/IntroPanel";

export default {
  name: "IntroPage",
  components: {IntroPanel},
  data: () => ({
    state: 0,
    titledialog: "Tambah Intro",
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Image',
        value: 'image'
      },
      {
        text: 'Title',
        value: 'title'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'No Urut',
        value: 'nourut'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  computed: {
      ...mapState('IntroStore', ['intros','totalintro','loading', 'dialog', 'selected'])
  },
  methods: {
    ...mapActions('IntroStore', ['setdialog','getAllIntro', 'deleteIntro', 'addIntro', 'editIntro', 'setSelectedData', 'resetselected']),
    openaddnew() {
      this.state=1
      this.resetselected();
      this.setdialog(true)
    },
    simpan(){
      if(this.state===1){
        this.addIntro(this.selected)
      }else{
        this.editIntro(this.selected)
      }
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    uploaded(img){
      console.log(img);
    },
    openedit(data){
      this.setSelectedData(data)
      this.state=0
      this.setdialog(true)
    },
    clickedit(selectededit){
      this.state===0
      console.log(selectededit)
      this.setSelectedData(selectededit)
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteIntro(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
