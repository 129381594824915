import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export  const MenuServices = {
  getAllMenu,
  getAllJenisMenu,
  addMenu,
  deleteMenu,
  editMenu
}


function getAllMenu (opt) {
  var sort="id"
  var sorder="DESC"
  if(opt.sortBy){
    if(opt.sortBy[0]){
      sort=opt.sortBy[0]
    }
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }else{
      sorder="ASC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }

  if(opt.search==null){
    opt.search='';
  }

  if(typeof opt.groupid === 'undefined'){
    opt.groupid=''
  }
  if(opt.groupid ==null){
    opt.groupid=''
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/menu?search='+opt.search+'&groupid='+opt.groupid+'&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getAllJenisMenu (opt) {
  var sort="id"
  var sorder="DESC"
  if(opt.sortBy){
    // console.log(opt.sortBy)
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  opt.itemsPerPage=200;
  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/jenismenu?search='+opt.search+'&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function addMenu (postdata) {
  return fetch(API_URL + '/api/admin/v1/menu', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function editMenu (postdata) {
  return fetch(API_URL + '/api/admin/v1/menu', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function deleteMenu (id) {
  return fetch(API_URL + '/api/admin/v1/menu/'+id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
