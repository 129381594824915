import {IntroService} from "@/services/intro.service";

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  intros: [],
  totalintro: 0,
  selected: {
    image: '',
    title: 'Belaja Grosir',
    description: 'Nimati kemudahan untuk mengisi stok warung anda',
    status: 0
  },
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  resetselected ({ commit }, diag) {
    commit('resetdata', diag)
  },
  setSelectedData ({ commit }, data) {
    commit('setselecteddata', data)
  },
  setIimageselected ({ commit }, image) {
    commit('setimageselected', image)
  },
  getAllIntro({dispatch, commit },opt) {
    commit('setloading',true)
    IntroService.getAllIntro(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
      commit('resetdata')
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addIntro({ dispatch,commit },opt) {
    commit('setloading',true)
    IntroService.addIntro(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getAllIntro', {
        search: '',
        itemsPerPage: 10,
        page: 1,
        sortBy: ["id"]
      });
      commit('resetdata')
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editIntro({ dispatch,commit },opt) {
    commit('setloading',true)
    IntroService.editIntro(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getAllIntro', {
        search: '',
        itemsPerPage: 10,
        page: 1,
        sortBy: ["id"]
      });
      commit('resetdata')
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteIntro({ dispatch,commit },opt) {
    commit('setloading',true)
    IntroService.deleteIntro(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('getAllIntro', {
        search: '',
        itemsPerPage: 10,
        page: 1,
        sortBy: ["id"]
      });
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setimageselected (state, s) {
    state.selected.image=s
  },
  setselecteddata (state, s) {
    state.selected = s
  },
  resetdata (state) {
    state.selected={
      image: '',
      title: 'Belaja Grosir',
      description: 'Nimati kemudahan untuk mengisi stok warung anda',
      status: 0
    }
  },
  setdata (state, s) {
    state.intros = []
    state.intros = s.data.content
    state.totalintro = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const IntroStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
