
import { NewsService } from '@/services/news.services'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  news: [],
  totalnews: 0,
  tumb:'',
  image:'',
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    if(!diag){
      commit('settumb', '')
    }
    if(!diag){
      commit('setimage', '')
    }
    commit('setdialog', diag)
  },
  setTumb ({ commit }, image) {
    commit('settumb', image)
  },
  setImage ({ commit }, image) {
    commit('setimage', image)
  },
  getNews({dispatch, commit },opt) {
    commit('setloading',true)
    NewsService.getNews(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addNews({ dispatch,commit },opt) {
    commit('setloading',true)
    NewsService.addNews(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editNews({ dispatch,commit },opt) {
    commit('setloading',true)
    NewsService.editnews(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteNews({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    NewsService.deleteNews(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setimage (state, s) {
    state.image = s
  },
  settumb (state, s) {
    state.tumb = s
  },
  setdata (state, s) {
    state.news = []
    state.news = s.data.content
    state.totalnews = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const NewsStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
