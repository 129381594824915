const state = {
  show: false,
  type: null,
  message: null,
  dialogutama: false,
  colordialog: 'indigo',
  judul: '',
  color: ''
}

const actions = {
  success ({ commit }, message) {
    commit('success', message)
  },
  showdialogutama ({ commit }, message) {
    commit('showdialogutama', message)
  },
  closedialogutama ({ commit }) {
    commit('closedialogutama')
  },
  error ({ commit }, message) {
    commit('error', message)
  },
  clear ({ commit }, message) {
    commit('success', message)
  }
}

const mutations = {
  showdialogutama (state, message) {
    state.dialogutama = true
    state.message = message.info
    state.judul = message.judul
    state.colordialog = message.color
  },
  closedialogutama (state) {
    state.dialogutama = false
    state.message = ''
    state.judul = 'INFO'
    state.colordialog = 'indigo'
  },
  success (state, message) {
    state.type = 'alert-success'
    state.message = message
    state.show = true
    state.color = 'green'
  },
  error (state, message) {
    state.type = 'alert-danger'
    state.message = message
    state.color = 'red'
    state.show = true
  },
  clear (state) {
    state.type = null
    state.message = null
    state.show = false
  }
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
}
