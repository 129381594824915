import { DashboardService } from '@/services/dashboard.service'

const state = {
  loading:true,
  dialog:false,
  overlay:false,
  sumreg:{
    labels: [],
    datasets: []
  },
  totalsumreg:0,
  sumtrx: {
    labels: [],
    datasets: []
  },
  sumomsetnew:[],
  sumotrxnew:[],
  sumregusernew:[],
  totalsumtrx:0,
  dashboardata:{
    totaltrx:0,
    omset:0,
    totaluser:0,
    wallet:0
  }

}
const actions = {
  setloading({ commit },data){
    commit('setloading',data)
  },
  setdialog({ commit },diag) {
    commit('setdialog',diag)
  },
  getdashboarddata({commit}){
    commit('setloading',true)
    DashboardService.getdashboard().then(value => {
      commit('setloading',true)
      commit('setdashboardata',value)
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  getSumreguser({commit},opt){
    commit('setloading',true)
    DashboardService.getsumreguser(opt).then(value => {
     // commit('setloading',true)
      commit('setsumreguser',value)
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  async getSumTrx({commit}){
    commit('setloading',true)

    DashboardService.getsumtrx().then(value => {
      commit('setloading',true)

      commit('setsumtrx',value)
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
    // let a = await  DashboardService.getsumtrx();
    // commit('setsumtrx',a)
  },
}
const mutations = {
  setloading(state,s){
    state.loading=s;
  },
  setdialog(state,s){
    state.dialog=s;
  },
  setsumreguser(state,s){

    const tanggal= s.data.content.map(d=>d.tanggal);
    const jumlah= s.data.content.map(d=>d.total);
    state.sumreg = {
      labels: tanggal,
      datasets:[
        {
          label:"Tanggal",
          backgroundColor: '#f87979',
          data:jumlah
        }
      ]
    }
    state.sumregusernew=[];
    state.sumregusernew.push(['Tanggal','JmlReg']);
    for (let i = 0; i < s.data.content.length; i++) {
      state.sumregusernew.push([s.data.content[i].tanggal,s.data.content[i].total])
    }
    state.loading=false;
    state.overlay=false;
  },
  setsumtrx(state,s){
    const tanggal= s.data.content.map(d=>d.tanggal);
   // var jmltrx= s.data.content.map(d=>d.total);
    var omset= s.data.content.map(d=>d.omset);

    state.sumomsetnew=[];
    state.sumomsetnew.push(['Tanggal','Omset']);
    state.sumotrxnew=[];
    state.sumotrxnew.push(['Tanggal','JmlTrx']);
    for (let i = 0; i < s.data.content.length; i++) {
      state.sumomsetnew.push([s.data.content[i].tanggal,s.data.content[i].omset])
      state.sumotrxnew.push([s.data.content[i].tanggal,s.data.content[i].total])
    }

    state.sumtrx = {
      labels: tanggal,
      datasets:[
        {
          label:"omset",
          backgroundColor: '#f87979',
          data:omset
        }
      ]
    }

    state.loading=false;
    state.overlay=false;
  },
  setdashboardata(state,s){
    state.dashboardata=[];
    state.dashboardata=s.data;
    state.loading=false;
    state.overlay=false;
  }
}


export const Dashboard = {
  namespaced: true,
  state,
  actions,
  mutations
}
