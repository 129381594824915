
import { ProductService } from '@/services/product.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  priceplan: [],
  operator: [],
  terminalh2h: [],
  produkterminal:[]
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getPriceplan({dispatch, commit }) {
    commit('setloading',true)
    ProductService.getPricePlan().then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getProdukTerminalByIdT({dispatch, commit },opt) {
    commit('setloading',true)
    ProductService.getProdukterminalByIdTerminal(opt).then(value => {
      commit('setprodukterminal',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getOperator({dispatch, commit }) {
    commit('setloading',true)
    ProductService.getOperator().then(value => {
      commit('setdataoperator',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getTerminalH2h({dispatch, commit }) {
    commit('setloading',true)
    ProductService.getTerminalH2h().then(value => {
      commit('setdataterminalh2h',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.priceplan = []
    state.priceplan = s.data
    state.loading = false
    state.overlay = false
  },
  setprodukterminal (state, s) {
    state.produkterminal = []
    state.produkterminal = s.data
    state.loading = false
    state.overlay = false
  },
  setdataoperator (state, s) {
    state.operator = []
    state.operator = s.data
    state.loading = false
    state.overlay = false
  },
  setdataterminalh2h (state, s) {
    state.terminalh2h = []
    state.terminalh2h = s.data
    state.loading = false
    state.overlay = false
  }
}

export const ProductStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
