<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)">
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <div class="mt-6">
        <v-select
          label="Status"
          item-text="label"
          item-value="id"
          v-model="opsitable.status"
          :items="statuses"
        ></v-select>
      </div>
      <div class="mt-6">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="opsitable.awal"
              label="Tanggal Awal"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="opsitable.awal" @input="menu1 = false"></v-date-picker>
        </v-menu>
      </div>
      <div class="mt-6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="opsitable.akhir"
              label="Tanggal Akhir"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="opsitable.akhir" @input="menu2 = false"></v-date-picker>
        </v-menu>
      </div>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getListQrpayment(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="qrpayment"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalqr"
      :loading="loading"
      @update:options="getListQrpayment(opsitable)">
      <template v-slot:item.reseller="{ item }">
        {{item.reseller.idreseller+ '- ' +item.reseller.namareseller}}
      </template>
      <template v-slot:item.tumb="{ item }">
        <div class="pa-4">
          <v-img max-height="100" max-width="100" :src="item.tumb"/>
        </div>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="pa-4">
          <v-img max-height="200" max-width="200" :src="item.image"/>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.updateat="{ item }">
        <div class="ma-2">
          {{formatDate(item.updateat)}}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'QrPayment',
  data: ()=>({
    search: '',
    newdata: {},
    modaldate: false,
    menu1: false,
    menu2: false,
    date: new Date().toISOString().substr(0, 10),
    opsitable: {
      search: '',
      itemsPerPage: 10,
      page: 1,
      sortBy: ["id"],
      status:-1,
      awal: moment().add(7, 'hours').toISOString().substr(0, 10),
      akhir: moment().add(7, 'hours').toISOString().substr(0, 10)
    },
    titledialog: "Edit",
    state: 0,
    statuses: [
      {
        id: -1,
        label: 'Semua'
      },
      {
        id: 0,
        label: 'Pending'
      },
      {
        id: 1,
        label: 'Valid'
      }
    ],
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Users',
        value: 'reseller'
      },
      {
        text: 'NoInv',
        value: 'inv'
      },
      {
        text: 'QR',
        value: 'cr'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'reff',
        value: 'reff'
      },
      {
        text: 'Expired',
        value: 'expired'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
  },
  computed: {
    ...mapState('PaymentGatewayStore',['loading','dialog','qrpayment','totalqr']),
  },
  methods: {
    ...mapActions('PaymentGatewayStore',['setdialog','setImage','getListQrpayment','setloading']),
    simpan(){
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.titledialog="Edit"
      this.setdialog(true)
    },
  }
}
</script>

<style scoped>

</style>
