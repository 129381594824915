<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-col cols="12" md="12 ">
              <v-text-field label="Judul" v-model="newdata.label"></v-text-field>
              <v-text-field label="Link URL" v-model="newdata.link"></v-text-field>
              <BannerUpload label="Image" :value="bannerimage"/>
              <v-switch
                flat icon class="ml-4"
                inset
                v-model="newdata.status"
                label="Status"/>
            </v-col>
            <v-dialog
              ref="dialog"
              v-model="modaldate"
              :return-value.sync="newdata.expired"
              persistent
              width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  v-model="newdata.expired"
                  label="Expire Date">
                </v-text-field>
              </template>
              <v-date-picker v-model="newdata.expired" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modaldate = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(newdata.expired)">OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
        <v-icon dark>mdi-plus</v-icon>Tambah
      </v-btn>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getBanner(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="banners"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalbanner"
      :loading="loading"
      @update:options="getBanner(opsitable)">
      <template v-slot:item.image="{ item }">
        <div class="pa-4">
          <v-img max-height="200" max-width="200" :src="item.image"/>
        </div>
      </template>
      formatDate
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.expired="{ item }">
        {{formatDate(item.expired)}}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BannerUpload from '@/components/BannerUpload'
import moment from 'moment'
export default {
  name: 'Banner',
  components: { BannerUpload },
  data: ()=>({
    search: '',
    tab: '',
    modaldate: false,
    date: new Date().toISOString().substr(0, 10),
    newdata: {
      label:'',
      link:'',
      image:''
    },
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    state: 0,
    titledialog: "Tambah Banner",
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Judul',
        value: 'label'
      },
      {
        text: 'Image',
        value: 'image'
      },
      {
        text: 'Link URL',
        value: 'link'
      },
      {
        text: 'Kadaluarsa',
        value: 'expired'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {

  },
  computed: {
    ...mapState('Banner',['loading','dialog','banners','totalbanner','bannerimage'])
  },
  methods: {
    ...mapActions('Banner',['setdialog','setImage','getBanner','setloading','addBanner','editBanner','HapusBanner']),
    simpan(){
      if(this.bannerimage!==''){
        this.newdata.image=this.bannerimage
      }
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0;
      }
      if(this.state===1){
        this.addBanner(this.newdata)
      }else{
        this.editBanner(this.newdata)
      }
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    openaddnew(){
      this.state=1;
      this.newdata={}
      this.setImage('')
      this.titledialog="Tambah Banner"
      this.setdialog(true)
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.setImage(data.image)
      this.titledialog="Edit Banner"
      this.setdialog(true)
    },
    formatDate (value) {
      return moment(value).add(0, 'hours').format('YYYY/MM/DD')
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.HapusBanner(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
