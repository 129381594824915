<template>
  <v-container class="fill-height back" fluid>
    <v-app-bar clipped-left app flat dark color="transparent">
      <img @click="home" class="logo" alt="logo" height="36" src="../assets/irswhite.png"/>
      <v-spacer/>
      <v-toolbar-items>
        <v-btn text>Fitur</v-btn>
        <v-btn @click="login" text>Login</v-btn>
        <v-btn @click="register" text>Register</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-row justify="center" align-content="center" align="center" no-gutters>
      <div v-if="!acterror" class="d-flex flex-column mb-6 ma-1 white irma-box">
				<div class="irma-box-center align-center justify-center text-center">
					<v-icon color="green"  x-large class="icon-besar">mdi-checkbox-marked-circle</v-icon>
					<p class="title mt-2">User Activated Successfully</p>
					<v-btn @click="login()" depressed class="primary ma-10">Login</v-btn>
				</div>
			</div>
			<div v-if="acterror" class="d-flex flex-column mb-6 ma-1 white irma-box">
				<div class="irma-box-center align-center justify-center text-center">
					<v-row>
						<v-col cols="12">
							<v-icon color="red"  x-large class="icon-besar">report_problem</v-icon>
							<p class="title">{{acterrormsg}}</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-btn block depressed @click="home" color="primary">Kembali</v-btn>
						</v-col>
					</v-row>
				</div>

			</div>
		</v-row>
	</v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ActivateUser',
  data: () => ({
    token: ''
  }),
  created () {

  },
  mounted () {
    this.activate(this.$route.params.token)
  },
  computed: {
    ...mapState('authentication', ['status', 'loading', 'acterror', 'acterrormsg'])
  },
  methods: {
    ...mapActions('authentication', ['activate']),
    login () {
      this.$router.push('/')
    },
    register () {
      this.$router.push('/')
    },
    forgot () {
      this.$router.push('/forgot')
    },
    home () {
      this.$router.push('/')
    }
  }

}
</script>

<style scoped>
	.back {
		background-image: linear-gradient(to right, rgba(5, 11, 31, 0.8), rgba(5, 11, 31, 0.8)), url("https://www.paper.id/blog/wp-content/uploads/2018/11/digital-payment-India-1280x720.jpg");
		background-size: cover;
	}
	.irma-box {
		height: 400px;
		width: 400px;
		border-color: #cccccc;
		border-radius: 5px;
	}
	.irma-box-center {
		position: relative;
		margin: auto;
	}
	.icon-besar {
		font-size: 70px !important;
	}
</style>
