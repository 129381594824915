import { apiurl } from '@/services/config'
var qs = require('qs')
const BASE_URL = apiurl
export const AuthService = {
  login,
  logout,
  getme,
  forgotpassword,
  gantipasswordsetpass,
  gantipassword,
  activateUser,
  registerUser,
  facebookauth,
  googleauth,
  regAdmin,
  getconfig
}
function getconfig () {
  return fetch(BASE_URL + '/config', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    }
  }).then(handleResponse)
}

function regAdmin (reg) {
  return fetch(BASE_URL + '/registeradm', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify(reg)
  }).then(handleResponse)
}

function registerUser (reg) {
  return fetch(BASE_URL + '/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify(reg)
  }).then(handleResponse)
}

function activateUser (token) {
  return fetch(BASE_URL + '/activate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify({ token: token })
  }).then(handleResponse)
}

function gantipasswordsetpass (set) {
  return fetch(BASE_URL + '/resetpassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify({
      token: set.token,
      password: set.password,
      passwordconf: set.passwordconf
    })
  }).then(handleResponse)
}

function googleauth (idtoken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify({ code: idtoken }.code)
  }

  return fetch(BASE_URL + '/socialauth/google', requestOptions)
    .then(handleResponse)
    .then(data => {
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('refreshtoken', JSON.stringify(data))
      return data
    })
}

function facebookauth (token) {
  console.log(token)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify({ code: token }.code)
  }

  return fetch(BASE_URL + '/socialauth/facebook', requestOptions)
    .then(handleResponse)
    .then(data => {
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('refreshtoken', JSON.stringify(data))
      return data
    })
}

function forgotpassword (email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: JSON.stringify({ email: email }.email)
  }

  return fetch(BASE_URL + '/forgotpassword', requestOptions)
    .then(handleResponse)
}

function gantipassword (customer) {
  return fetch(BASE_URL + '/gantipassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    body: JSON.stringify(customer)
  }).then(handleResponse)
}

async function login (username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic Y2xpZW50MDE6c2VjcmV0MTIzNDU2'
    },
    body: qs.stringify({ grant_type: 'password', username, password })
  }

  return fetch(BASE_URL + '/oauth/token', requestOptions)
    .then(handleResponse)
    .then(data => {
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('refreshtoken', JSON.stringify(data))
      return data
    })
}

function logout () {
  console.log('CALL LOGOUT')
  localStorage.setItem('token', null)
  localStorage.clear()
}

function getme () {
  return fetch(BASE_URL + '/api/v1/user', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)

    if (response.status === 200) {
      if (data.access_token) {
        return data
      }
    }

    if (!data.success) {
      if (response.status === 401) {
        //logout()
        //location.reload(true)
      }
      const error = (data && data.msg) || data.message || data.error || data.error_description || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
