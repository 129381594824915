<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)">
    <v-tabs  v-model="tab">
      <v-tab>User</v-tab>
      <v-tab>Agen</v-tab>
      <v-tab>KYC</v-tab>
      <v-tab-item>
        <User/>
      </v-tab-item>
      <v-tab-item>
        <Agen/>
      </v-tab-item>
      <v-tab-item>
        <DetailKyc/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import User from '@/components/userdetail/user'
import Agen from '@/components/userdetail/Agen'
import DetailKyc from '@/components/userdetail/DetailKyc'

export default {
  name: 'UserDetail',
  components: { DetailKyc, Agen, User },
  data: () => ({
      tab: null
  }),
  mounted () {
    console.log(this.$route.params.id)
    this.getUserDetail(this.$route.params.id)
  },
  computed: {
    ...mapState('UserStore',['dialoguserdetail','userdetail'])
  },
  methods: {
    ...mapActions('UserStore',['setdialoguserdetail','getUserDetail'])
  }
}
</script>

<style scoped>

</style>
