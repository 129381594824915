import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export  const SidompulServices = {
  getAllCategory,
  deleteCategory,
  updateCategory,
  addCategory,
  fetchSidompulMenu,
  getSidompulProducts,
}

function getAllCategory (opt) {
  var sort="id"
  var sorder="DESC"
  if(opt.sortBy){
    if(opt.sortBy[0]){
      sort=opt.sortBy[0]
    }
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }else{
      sorder="ASC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }

  if(opt.search==null){
    opt.search='';
  }

  if(typeof opt.groupid === 'undefined'){
    opt.groupid=''
  }
  if(opt.groupid ==null){
    opt.groupid=''
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/sidompul/category?search='+opt.search+'&brand='+opt.brand+'&page='+page+'&size='+opt.itemsPerPage+"&sort=brand,ASC&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getSidompulProducts (opt) {
  return fetch(API_URL + '/api/admin/v1/sidompul/products?brand='+opt.brand+"&category="+opt.category+"&tujuan="+opt.tujuan, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function fetchSidompulMenu (brand) {
  return fetch(API_URL + '/api/admin/v1/sidompul/appcategory?brand='+brand, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function updateCategory (postdata) {
  return fetch(API_URL + '/api/admin/v1/sidompul/category', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function deleteCategory (postdata) {
  return fetch(API_URL + '/api/admin/v1/sidompul/category', {
    method: 'DELETE',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function addCategory (postdata) {
  return fetch(API_URL + '/api/admin/v1/sidompul/category', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
