
import { PopupService } from '@/services/popup.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  popup: [],
  totalpopup: 0,
  image:'',
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    if(!diag){
      commit('setimage', '')
    }
    commit('setdialog', diag)
  },
  setImage ({ commit }, image) {
    commit('setimage', image)
  },
  getPopup({dispatch, commit },opt) {
    commit('setloading',true)
    PopupService.getPopup(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addPopup({ dispatch,commit },opt) {
    commit('setloading',true)
    PopupService.addPopup(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editPopup({ dispatch,commit },opt) {
    commit('setloading',true)
    PopupService.editPopup(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deletePopup({ dispatch,commit },opt) {
    commit('setloading',true)
    PopupService.deletePopup(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }

}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.popup = []
    state.popup = s.data.content
    state.totalpopup = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setimage (state, s) {
    state.image = s
  },
}

export const PopupStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
