<template>
  <v-dialog v-model="dialogimageshare" persistent width="600">
    <v-card>
      <v-toolbar dense class="primary" dark>
        <v-toolbar-title>ImageReferral</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="setdialogimageshare(false)"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-layout wrap>
          <v-col cols="12"  xs="6" sm="4" md="6" lg="8">
            <div class="text-center mt-8">
              <input
                hidden
                type="file"
                accept="image/*"
                ref="imageshareupload"
                v-on:change="upload($event.target.files)"
              />
              <div  v-if="loadingupload" class="imglogo">
                <v-progress-circular
                  :size="50"
                  :width="2"
                  class="imgupload"
                  color="red"
                  indeterminate
                ></v-progress-circular>
                <div class="upload-text mt-2">
                  Uploading....
                </div>
              </div>
              <v-img alt="ok" v-else max-height="200" max-width="400" contain :src="appsetting.imagesharerefferal"/>
            </div>
          </v-col>
          <v-col cols="12"  xs="6" sm="4" md="6" lg="4">
            <div class="mt-1"><v-btn  v-if="!loadingupload" text @click.native="openfile" class="primary"><v-icon class="mr-2">mdi-cloud-upload</v-icon>Ganti</v-btn></div>
          </v-col>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="simpan" text color="green">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { serviceUpload } from '@/services/serviceupload'

export default {
  name: 'DialogImageShare',
  data: () => ({
    loadingupload:false,
  }),
  computed: {
    ...mapState('SettingStore', ['dialogimageshare', 'appsetting'])
  },
  methods: {
    ...mapActions('SettingStore', ['setdialogimageshare', 'updateSetting']),
    simpan(){
      this.updateSetting(this.appsetting)
    },
    openfile () {
      this.$refs.imageshareupload.click()
    },
    showPreview: async function (file) {
      var reader = new FileReader()
      if (file) {
        this.loadingupload = true
        console.log('uploading')
        serviceUpload.newuploadimage(file)
          .then(response => {
            this.loadingupload = false
            console.log(response.data)
            this.done = true
            window.getApp.$emit('DONE_SET_IMAGE_SHARE', response.data)
          })
          .catch(error => {
            this.loadingupload = false
            this.done = false
            console.log('err upload')
            console.log(error)
          })
        reader.readAsDataURL(file)
        const result = await new Promise((resolve) => {
          reader.onload = function () {
            resolve(reader.result)
          }
        })
        this.src = result
        this.value = result
      }
    },
    upload: function (files) {
      this.showPreview(files[0])
    }
  }
}
</script>

<style scoped>

</style>
