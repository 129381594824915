<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-tabs  v-model="tab">
            <v-tab>Data</v-tab>
            <v-tab>Photo</v-tab>
            <v-tab-item>
              <data-umum :value="newdata"/>
            </v-tab-item>
            <v-tab-item>
              <div>
                <v-zoomer style="width: 500px; height: 500px; border: solid 1px silver;">
                  <img alt="ktpimg" :src="newdata.ktpImage" style="object-fit: contain; width: 100%; height: 100%;">
                </v-zoomer>
                <v-zoomer style="width: 500px; height: 500px; border: solid 1px silver;">
                  <img alt="selfimg" :src="newdata.selfieImage" style="object-fit: contain; width: 100%; height: 100%;">
                </v-zoomer>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn v-if="newdata.status!==0" elevation="0" dark @click="reject" color="red">Reject</v-btn>
          <v-btn v-if="newdata.status!==1" elevation="0" dark @click="approve" color="green">Approve</v-btn>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <div class="mt-6">
        <v-select
          label="Status"
          item-text="label"
          item-value="id"
          v-model="opsitable.status"
          :items="statuses"
        ></v-select>
      </div>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getKyc(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="kyc"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalkyc"
      :loading="loading"
      @update:options="getKyc(opsitable)">
      <template v-slot:item.tumb="{ item }">
        <div class="pa-4">
          <v-img max-height="100" max-width="100" :src="item.tumb"/>
        </div>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="pa-4">
          <v-img max-height="200" max-width="200" :src="item.image"/>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.updateat="{ item }">
        <div class="ma-2">
          {{formatDate(item.updateat)}}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'
import moment from 'moment'
import DataUmum from '@/components/kyc/DataUmum'

export default {
  name: 'Kyc',
  components: { DataUmum },
  data: () => ({
    search: '',
    tab: '',
    newdata: {
      name:'',
      url:'',
      image:''
    },
    titledialog: "Update KYC",
    modaldate: false,
    date: new Date().toISOString().substr(0, 10),
    opsitable: {
      search: '',
      itemsPerPage: 10,
      page: 1,
      sortBy: ["id"],
      status:-1
    },
    statuses: [
      {
        id: -1,
        label: 'Semua'
      },
      {
        id: 0,
        label: 'Pending'
      },
      {
        id: 1,
        label: 'Approve'
      }
    ],
    state: 0,
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Nama',
        value: 'namalengkap'
      },
      {
        text: 'NoKtp',
        value: 'noktp'
      },
      {
        text: 'Alamat',
        value: 'alamat'
      },
      {
        text: 'Provinsi',
        value: 'provinsi'
      },
      {
        text: 'Kabupaten/Kota',
        value: 'kota'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
  },
  computed: {
    ...mapState('KycStore',['loading','dialog','kyc','totalkyc']),
  },
  methods: {
    ...mapActions('KycStore',['setdialog','getKyc','updateKyc']),
    formatAngka(val){
      return Utils.formatAngka(val)
    },
    approve(){
      this.newdata.status=1
      this.updateKyc(this.newdata)
    },
    reject(){
      this.newdata.status=0;
      this.updateKyc(this.newdata)
    },
    simpan(){
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0;
      }
      this.updateKyc(this.newdata)
    },
    colorStatus(val){
      if(val===0){
        return "orange";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Pending";
      }
      if(val===1){
        return "Aprove";
      }
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.titledialog="Edit"
      this.setdialog(true)
    },
  }
}
</script>

<style scoped>

</style>
