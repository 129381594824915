<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="products"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalproducts"
      :loading="loading"
      @update:options="getProducts()">
      <template v-slot:top>
        <v-toolbar elevation="0" class="mt-0">
          <v-btn small elevation="0" class="ml-0 mt-0" color="primary" @click.native="getProducts()">
            <v-icon dark>mdi-refresh</v-icon>Refresh
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.price="{ item }">
        <div class="ma-1 text-xs-left">
          {{formatNomor(item.price)}}
        </div>
      </template>
    </v-data-table>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Utils from "@/plugins/utils";

export default {
  name: "RitaPanale",
  data: ()=>({
    search: "",
    opsitable: {
      search: '',
      brand: "",
      itemsPerPage: 10,
      page: 1,
      sortBy: ["categorygroup"],
    },
    headers: [
      {
        text: 'Nama Produk',
        value: 'productName'
      },
      {
        text: 'Code',
        value: 'productCode'
      },
      {
        text: 'Price',
        value: 'price'
      },
    ]
  }),
  computed: {
    ...mapState('RitaStore',['loading','dialog','products',"totalproducts"])
  },
  methods: {
    ...mapActions('RitaStore', ['setdialog', 'setloading','getProducts']),
    formatNomor(val){
      return Utils.formatAngka(parseInt(val))
    }
  }
}
</script>

<style scoped>

</style>
