
import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export const DashboardService = {
  getsumreguser,
  getsumtrx,
  getdashboard
}
function getdashboard () {
  return fetch(API_URL + '/api/admin/v1/dashboard', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getsumreguser () {
  var sort="id"
  var sorder="ASC"
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/summary/reguser?size=100&sort='+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getsumtrx () {
  var sort="id"
  var sorder="ASC"
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/summary/trx?size=100&sort='+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
