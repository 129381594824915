<template>
  <v-app id="app">
    <template v-if="!$route.meta.public">
      <app-drawer/>
      <AppToolbar/>
      <v-main class="ma-4">
        <PageHeader class="ml-1 mb-4" v-if="$route.meta.breadcrumb"></PageHeader>
        <v-dialog v-model="alert.dialogutama" persistent max-width="400">
          <v-card>
            <v-card-title class="headline white--text" :class="alert.colordialog">{{alert.judul}}</v-card-title>
            <v-card-text>{{ alert.message }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click.native="closedialog">TUTUP</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-main>
    </template>
    <template v-else>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <v-snackbar :timeout="3000" bottom right :color="alert.color" v-model="alert.show">
      {{ alert.message }}
      <!--      <v-btn dark text @click.native="alert.show = false" icon>-->
      <!--        <v-icon>close</v-icon>-->
      <!--      </v-btn>-->
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="alert.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppEvents from './event'
import AppDrawer from './components/AppDrawer'
import AppToolbar from './components/ToolBar'
import PageHeader from './components/PageHeader'
export default {
  components: {
    AppDrawer, AppToolbar, PageHeader
  },
  name: 'App',
  data: () => ({
    expanded: true,
    snackbar: {
      show: true,
      text: '',
      color: ''
    },
    token: localStorage.getItem("token")
  }),
  mounted () {
   // console.log(this.$route)
   // console.log(this.token)
    if (!this.$route.meta.public) {
      if (this.token) {
        this.$router.push('/dashboard')
      } else {
        console.log()
        if (!this.$route.params.token) {
          this.$router.push('/login')
        }
      }
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      account: state => state.account
    })
  },
  created () {
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback)
    })
    window.getApp = this
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      closedialogutama: 'alert/closedialogutama',
      getme: 'authentication/getme'
    }),
    ...mapActions('TicketSupportStore', ['getTicketSupports']),
    closedialog () {
      this.genId()
      this.closedialogutama()
      this.clearresponse()
    }
  }
}

</script>
