

import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export  const RitaService = {
  getProducts
}
function getProducts () {
  return fetch(API_URL + '/api/admin/v1/rita/products', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
