

import {RitaService} from "@/services/rita.service";


const state = {
  loading: false,
  dialog: false,
  overlay: false,
  icon:'',
  totalcategory:0,
  totalproducts:0,
  products: [     {
    "productCode": "RMYS",
    "productName": "Paket Roaming Malaysia 100GB 7 hari",
    "price": "94700"
  }],
  brand:[
    {id:"XL",nama:"XL"},
    {id:"AXIS",nama:"AXIS"}
  ],
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getProducts({dispatch, commit }) {
    commit('setloading',true)
    RitaService.getProducts().then(value => {
      commit('setproducts',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdatacategory (state, s) {
    state.category = []
    state.category = s.data.content
    state.totalcategory = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setproducts (state, s) {
    state.products = []
    state.products = s.data
    state.totalproducts=s.data.length;
    state.loading = false
    state.overlay = false
  },
}

export const RitaStore = {
  namespaced: true,
  state,
  actions,
  mutations
}

