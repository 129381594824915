
import { StrukService } from '@/services/struk.service'


const state = {
  loading: false,
  dialog: false,
  overlay: false,
  struk: [],
  totalstruk: 0,
  strukdata: {}
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getstruklist({dispatch, commit },opt) {
    commit('setloading',true)
    StrukService.getStruk(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getStrukbyid({dispatch, commit },id) {
    commit('setloading',true)
    StrukService.getByid(id).then(value => {
      commit('setstruk',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addStruk({ dispatch,commit },opt) {
    commit('setloading',true)
    StrukService.addStruk(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editStruk({ dispatch,commit },opt) {
    commit('setloading',true)
    StrukService.updateStruk(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  deleteStruk({ dispatch,commit },id) {
    commit('setloading',true)
    StrukService.deleteStruk(id).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.struk = []
    state.struk = s.data.content
    state.totalstruk = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setstruk (state, s) {
    state.strukdata = []
    state.strukdata = s.data
    state.loading = false
    state.overlay = false
  }
}

export const StrukStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
