<template>
    <v-container fluid class="fill-height back">
        <v-app-bar clipped-left app flat dark color="transparent">
            <img @click="home" class="logo" height="150" src="https://icon.irmastore.id/logo-irma-white.png"/>
            <v-spacer/>
            <v-toolbar-items>
                <v-btn text>Fitur</v-btn>
                <v-btn @click="login" text>Login</v-btn>
                <v-btn @click="register" text>Register</v-btn>
            </v-toolbar-items>
        </v-app-bar>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-0">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Setup Password</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="valid" ref="form" lazy-validation>
                            <v-text-field
                                    id="passwordbaru"
                                    label="Password baru"
                                    name="passwordbaru"
                                    :type="show1 ? 'text' : 'password'"
                                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                    @click:append="show1 = !show1"
                                    v-model="passwordbaru"
                                    :rules="passrules"
                            ></v-text-field>
                            <v-text-field
                                    id="passwordconf"
                                    label="Password baru lagi"
                                    name="passwordbaruconf"
                                    :type="show1 ? 'text' : 'password'"
                                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                    @click:append="show1 = !show1"
                                    v-model="passwordbaruconf"
                                    :rules="passrules"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="resetpas" depressed :loading="loading" color="primary lg">Set Password</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ResetPassToken',
  data: () => ({
    show1: false,
    valid: false,
    passrules: [
      v => !!v || 'Field is required',
      v => (v && v.length >= 4) || 'password minimum 4 characters'
    ],
    passwordbaru: '',
    passwordbaruconf: ''
  }),
  computed: {
    ...mapState('authentication', ['status', 'loading'])
  },
  methods: {
    ...mapActions('authentication', ['setpass']),
    resetpas () {
      if (this.valid) {
        this.setpass({
          token: this.$route.params.token,
          password: this.passwordbaru,
          passwordconf: this.passwordbaruconf
        })
      }
    },
    register () {
      this.$router.push('/register')
    },
    forgot () {
      this.$router.push('/forgot')
    },
    home () {
      this.$router.push('/')
    },
    login () {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.back {
    background-image: linear-gradient(to right, rgba(5, 11, 31, 0.8), rgba(5, 11, 31, 0.8));
    background-size: cover;
}
</style>
