<template>
  <div>
    <v-divider></v-divider>
    <v-row class="mt-5">
      <v-col cols="12" lg="2" md="4">
          <div>
            <v-img max-width="500" :src="selectedproduct.preview"></v-img>
          </div>
      </v-col>
      <v-col cols="12" lg="8" md="6">
        <div>NamaProduk : {{selectedproduct.nama}}</div>
        <div>Harga : {{formatAngka(selectedproduct.price)}}</div>
        <div>Deskripsi  :</div>
        <div v-html="selectedproduct.description" style=""></div>
      </v-col>
      <v-col cols="12" lg="2" md="2">
        <div>
          <v-btn @click="tambahcart" :disabled="selectedproduct.incart" text dark class="deep-orange lighten-1">Beli</v-btn>
        </div>
      </v-col>
    </v-row>
    <div class="mt-4"></div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'

export default {
  name: 'DetailProductStore',
  data:() => ({
    sel:{}
  }),
  computed: {
    ...mapState('IRSXStore', ['selectedproduct'])
  },
  methods: {
    ...mapActions('IRSXStore', ['addtochart','setselectedproduct','setincartbyid']),
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    tambahcart(){
      this.sel=this.selectedproduct
      this.sel.incart=true
      this.setselectedproduct(this.sel)
      this.setincartbyid(this.selectedproduct.id)
      this.addtochart(this.sel)
    }
  }
}
</script>

<style scoped>

</style>
