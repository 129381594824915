<template>
  <div>
    <v-dialog scrollable v-model="dialoggroup" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialoggroup(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <div class="mt-4"/>
          <v-text-field label="Nama Group" v-model="newdata.nama"></v-text-field>
          <v-text-field type="number" label="Posisi" v-model="newdata.posisi"></v-text-field>
          <v-row class="ml-2">
            <v-switch
              flat icon
              inset
              v-model="newdata.home"
              label="Home"/>
            <v-switch
              flat icon class="ml-4"
              inset
              v-model="newdata.status"
              label="Status"/>
          </v-row>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialoggroup(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="menugroup"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalmenugroup"
      :loading="loading"
      @update:options="getMenuGroup(opsitable)">
      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-text-field
            prepend-icon="mdi-text-search"
            placeholder="Type something"
            v-model="opsitable.search" clearable
            hide-details
            filled rounded dense
            @click:clear="clearFilter"
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
            <v-icon dark>mdi-plus</v-icon>Tambah
          </v-btn>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getMenuGroup(opsitable)">
            <v-icon dark>mdi-refresh</v-icon>Refresh
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn x-small fab text @click.native="openedit(item)" class="primary mr-1">
          <v-icon small>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-btn icon x-small fab text @click.native="deleteconfm(item)" dark class="red  ml-1">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MenuGroup',
  data: () => ({
    titledialog: 'Edit',
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["posisi"],
    },
    newdata: {
      posisi:0,
    },
    state: 0,
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Group',
        value: 'nama'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Posisi',
        value: 'posisi'
      },
      {
        text: "Action",
        value: 'action'
      }
    ],
    delete_id: 0
  }),
  mounted () {
  },
  computed: {
    ...mapState('MenuStore',['menugroup','totalmenugroup','dialoggroup','loading'])
  },
  methods: {
    ...mapActions('MenuStore',['getMenuGroup','deleteMenuGroup','setdialoggroup','addMenuGroup','editMenuGroup']),
    clearFilter(){
      this.opsitable.search="";
      this.getMenuGroup(this.opsitable)
    },
    simpan(){
      if(this.newdata.home){
        this.newdata.home=1
      }else{
        this.newdata.home=0
      }
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0;
      }
      if(this.state===1){
        this.addMenuGroup(this.newdata)
      }else{
        this.editMenuGroup(this.newdata)
      }
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    openaddnew(){
      this.state=1;
      this.newdata={
        icon: ''
      }
      this.titledialog="Tambah Group"
      this.setdialoggroup(true)
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.titledialog="Edit group"
      this.setdialoggroup(true)
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteMenuGroup(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
