<template>
  <v-layout>
    <v-dialog scrollable v-model="dialogneworder" max-width="600" persistent>
    <v-card class="mx-auto">
      <v-toolbar color="primary" dark flat dense cad>
        <v-toolbar-title class="subheading">Konfirmasi Pembelian</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="setdialogneworder(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div>
          <OrderSukses :show="donepayment"/>
        </div>
        <LoadingPayment :loading="loadingpayment"/>
        <v-list v-if="!loadingpayment && !donepayment" dense class="pa-0">
          <template v-for="(item, index) in cart">
            <v-list-item :key="index">
              <v-list-item-avatar><v-img :src="item.preview"></v-img> </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.nama }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <div class="pa-2">{{formatAngka(item.price)}}</div>
                  <v-btn class="" @click="deleteItemCart(index)" icon> <v-icon color="red">mdi-delete</v-icon></v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div v-if="!donepayment" class="text-body-1 green--text"><h4>Total Order: {{formatAngka(totalamount)}}</h4></div>
        <v-spacer></v-spacer>
        <v-btn text v-if="!donepayment" @click="lanjutOrder" dark color="green">lanjut</v-btn>
        <v-btn v-if="donepayment" text @click="closeDialogOrder" dark color="red">Selesai</v-btn>
        <v-btn v-if="!donepayment" text @click="setdialogneworder(false)" dark color="red">Tutup</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-tabs  v-model="tab">
      <v-tab>Market</v-tab>
      <v-tab>Order</v-tab>
      <v-tab-item>
        <Market/>
      </v-tab-item>
      <v-tab-item>
        <Orders/>
      </v-tab-item>
    </v-tabs>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'
import Market from '@/components/irsxstore/Market'
import Orders from '@/components/irsxstore/Orders'
import LoadingPayment from '@/components/irsxstore/LoadingPayment'
import OrderSukses from '@/components/irsxstore/OrderSukses'

export default {
  name: 'StorePage',
  components: { OrderSukses, LoadingPayment, Orders, Market },
  data: () => ({
    dialogdetail:true,
    tab:null,
    selectedcategory: null
}),
  mounted () {
    this.getCategories();
    this.getProducts();
  },
  computed: {
    ...mapState('IRSXStore', ['categories','products','dialogneworder','cart','loadingpayment','donepayment']),
    totalamount: function(){
      let tot=0
      for (let i = 0; i < this.cart.length; i++) {
        tot +=this.cart[i].price
      }
      return tot;
    },
  },
  methods: {
    ...mapActions('IRSXStore', ['cleanupOrder','getProducts','getCategories','addtochart','clearchart','setincart','setdialogneworder','deleteItemCart','orderRequest']),
    closeDialogOrder(){
      this.cleanupOrder();
      this.setdialogneworder(false);
    },
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    onseleccat(item){
      this.selectedcategory=item
    },
    changeBrand(item){
      console.log(item)
    },
    tambahcart(item){
      item.incart=true
      this.addtochart(item)
    },
    lanjutOrder(){
      this.$confirm('Yakin lanjut ke proses pembayaran ?', { color: 'green',title:'Lanjut',icon:'mdi-cog' }).then(res => {
        if (res) {
          this.orderRequest(this.cart)
        }
      })
    }
  }
}
</script>

<style scoped>
.borderkotak {
  border: thin solid rgba(0,0,0,.12)
}
.my-custom-dialog {
  align-self: flex-end;
}

</style>
