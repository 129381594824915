<template>
  <div class="ma-4">
    <v-col cols="12" md="12" lg="4">
      <v-row>
        <v-switch
          flat icon class="ml-4"
          inset
          v-model="appsetting.enableva" />
        <v-text-field type="number" label="Fee Virtual Account" v-model="appsetting.vafee"></v-text-field>
        <v-text-field type="number" class="ml-2" label="Minimum" v-model="appsetting.vafee"></v-text-field>
      </v-row>
      <v-row>
        <v-switch
          flat icon class="ml-4"
          inset
          v-model="appsetting.enablealfa"
          />
        <v-text-field type="number" label="Fee Alfamart" v-model="appsetting.alfafee"></v-text-field>
        <v-text-field type="number" class="ml-2" label="Minimum" v-model="appsetting.alfamin"></v-text-field>
      </v-row>
      <v-row>
        <v-switch
          flat icon class="ml-4"
          inset
          v-model="appsetting.enableqris"
          />
        <v-text-field type="number" label="Fee Qris" v-model="appsetting.qrisfee"></v-text-field>
        <v-text-field type="number" class="ml-2" label="Minimum" v-model="appsetting.qrismin"></v-text-field>
      </v-row>
      <v-row>
        <v-switch
          flat icon class="ml-4"
          inset
          v-model="appsetting.enableovo"
          />
        <v-text-field type="number" label="Fee OVO" v-model="appsetting.ovofee"></v-text-field>
        <v-text-field v-model="appsetting.ovomin" type="number"  class="ml-2" label="Minimum"></v-text-field>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="simpan" :loading="loading" color="primary"><v-icon>mdi-content-save</v-icon>Simpan</v-btn>
      </v-row>

    </v-col>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PaymentGatewaySetting',
  data: () => ({

  }),
  mounted () {
  },
  computed: {
    ...mapState('SettingStore',['loading','dialog','appsetting'])
  },
  methods: {
    ...mapActions('SettingStore',['getSetting','updateSetting']),
    simpan(){
      if(this.appsetting.enablealfa){
        this.appsetting.enablealfa=1
      }else{
        this.appsetting.enablealfa=0
      }
      if(this.appsetting.enableva){
        this.appsetting.enableva=1
      }else{
        this.appsetting.enableva=0
      }
      if(this.appsetting.enableovo){
        this.appsetting.enableovo=1
      }else{
        this.appsetting.enableovo=0
      }
      if(this.appsetting.enableqris){
        this.appsetting.enableqris=1
      }else{
        this.appsetting.enableqris=0
      }
      this.updateSetting(this.appsetting)
    }
  }
}
</script>

<style scoped>

</style>
