<template>
    <div class="grey lighten-5 pa-4">
      <v-dialog v-model="picdialog" width="550">
        <v-card>
          <v-toolbar color="primary" dark flat dense cad>
            <v-toolbar-title class="subheading">Image</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="picdialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div>
              <v-zoomer style="width: 500px; height: 500px; border: solid 1px silver;">
                <img :src="pic" style="object-fit: contain; width: 100%; height: 100%;">
              </v-zoomer>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col xs="12" sm="12" md="4" lg="4">
          <div>
            <v-text-field  dense outlined label="Nama" v-model="userdetail.appKyc.namalengkap" readonly></v-text-field>
            <v-text-field  dense outlined label="NoKTP" v-model="userdetail.appKyc.noktp" readonly></v-text-field>
            <v-text-field  dense outlined label="TGL Lahir" v-model="userdetail.appKyc.tgllahir" readonly></v-text-field>
          </div>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="4">
          <v-img style="cursor: pointer" width="300" height="300" @click.native="openpic(userdetail.appKyc.ktpImage)"  aspect-ratio="1.4" contain :src="userdetail.appKyc.ktpImage">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="4">
          <v-img style="cursor: pointer" width="300" height="300" @click.native="openpic(userdetail.appKyc.selfieImage)"  aspect-ratio="1.4" contain :src="userdetail.appKyc.selfieImage">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DetailKyc',
  data: () => ({
    picdialog:false,
    pic:''
  }),
  computed: {
    ...mapState('UserStore',['dialoguserdetail','userdetail'])
  },
  methods:{
    ...mapActions('UserStore',[]),
    openpic(image){
      this.pic=image
      this.picdialog=true
    }
  }
}
</script>

<style scoped>

</style>
