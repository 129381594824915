<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="products"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalproducts"
      :loading="loading"
      @update:options="getCategory(opsitable)">
      <template v-slot:top>
        <v-toolbar elevation="0" class="mt-2">

            <v-select  style="width: 50px;!important;" class="mt-4"
                     item-text="nama"
                      @change="getCategoryFull(opsitable)"
                      filled
                      v-model="opsitable.brand" item-value="id" :items="brand"  label="Brand">

            </v-select>
          <v-col cols="4">
            <v-autocomplete class="mt-4"  filled    @change="getProducts(opsitable)" v-model="opsitable.category" item-text="label" item-value="value" :items="category">

            </v-autocomplete>
          </v-col>

            <v-btn small elevation="0" class="ml-2 mt-3" color="primary" @click.native="getProducts(opsitable)">
              <v-icon dark>mdi-refresh</v-icon>Refresh
            </v-btn>

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.dompulPrice="{ item }">
        <div class="ma-1 text-xs-left">
          {{formatNomor(item.dompulPrice)}}
        </div>
      </template>
      <template v-slot:item.productImage="{ item }">
        <div class="ma-1 text-xs-left">
          <v-img max-width="60" :src="item.productImage"></v-img>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Utils from "@/plugins/utils";

export default {
  name: "SidompulProducts",
  data: ()=>({
    search:"",
    opsitable: {
      search:'',
      tujuan:"081700000",
      brand:"",
      category:"",
      itemsPerPage: 10,
      page:1,
      sortBy: ["categorygroup"],
    },
    headers: [
      {
        text: 'Image',
        value: 'productImage',
      },
      {
        text: 'Category',
        value: 'productCategory'
      },
      {
        text: 'Code',
        value: 'productCode'
      },
      {
        text: 'Nama Produk',
        value: 'productName'
      },
      {
        text: 'Dompul Price',
        value: 'dompulPrice'
      },
    ]
  }),
  computed: {
    ...mapState('sidompulStore',['loading','dialog','category','totalcategory','brand','products',"totalproducts"])
  },
  methods: {
    ...mapActions('sidompulStore', ['setdialog', 'setloading', 'getCategory', 'updateCategory', 'deleteCategory', 'syncCategory','getProducts','getCategoryFull']),
    formatNomor(val){
      return Utils.formatAngka(parseInt(val))
    }
  }
}
</script>

<style scoped>

</style>
