<template>
  <div class="ma-8">
    <v-layout row wrap>
      <v-flex lg3  sm6 xs12>
        <v-card elevation="0">
          <v-card-text>
            <v-text-field filled dense outlined label="UserId" disabled v-model="userdetail.user.id" readonly></v-text-field>
            <v-text-field filled dense outlined label="Nama" v-model="userdetail.user.nama" readonly></v-text-field>
            <v-text-field filled dense outlined label="Phone" v-model="userdetail.user.phone" readonly></v-text-field>
            <v-text-field filled dense outlined label="Email" v-model="userdetail.user.email" readonly></v-text-field>
            <v-text-field filled dense outlined label="Waktu Register" v-model="createtimeformated" readonly></v-text-field>
            <v-text-field filled dense outlined label="Waktu Update" v-model="udpatetimeformated" readonly></v-text-field>
            <v-chip v-if="userdetail.user.aktif===1" class="green" text-color="white">
              <v-icon class="white--text" left>mdi-check-bold</v-icon>
              Aktif</v-chip>
            <v-chip v-if="userdetail.user.aktif===0" class="red" text-color="white">
              <v-icon class="white--text" left>mdi-cancel</v-icon>
              Non-Aktif</v-chip>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn v-if="userdetail.user.aktif===1" @click.native="nonaktifkan(userdetail.user)" text dark class="red"> <v-icon class="white--text" left>mdi-close-box</v-icon>NonAktifkan</v-btn>
            <v-btn v-if="userdetail.user.aktif===0" @click.native="aktifkan(userdetail.user)" text dark class="green"> <v-icon class="white--text" left>mdi-check-bold</v-icon>Aktifkan</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'user',
  data: () => ({

  }),
  computed: {
    ...mapState('UserStore',['dialoguserdetail','userdetail']),
    createtimeformated(){
      return this.formatDate(this.userdetail.user.createat)
    },
    udpatetimeformated(){
      return this.formatDate(this.userdetail.user.updateat)
    }
  },
  methods: {
    ...mapActions('UserStore',['doeditUser']),
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    resendAct(item){
      this.delete_id = item.id
      this.$confirm('Resend Link Aktivasi ?', { color: 'green',title:'Link Aktivasi',icon:'mdi-account-reactivate' }).then(res => {
        if (res) {
          this.deleteRunningtext(this.delete_id)
        }
      })
    },
    aktifkan(item){
      let usr = item
      this.$confirm('Yakin akan Aktifkan ?', { color: 'green',title:'Aktifkan',icon:'mdi-check' }).then(res => {
        if (res) {
          usr.aktif=1
          this.doeditUser(usr)
        }
      })
    },
    nonaktifkan(item){
      let usr = item
      this.$confirm('Yakin akan Non-Aktifkan ?', { color: 'red',title:'Non-Aktifkan',icon:'mdi-cancel' }).then(res => {
        if (res) {
          usr.aktif=0
          this.doeditUser(usr)
        }
      })
    },
    resetpassword(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan Resetpassword ?', { color: 'red',title:'ResetPassword',icon:'mdi-lock' }).then(res => {
        if (res) {
          this.deleteRunningtext(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
