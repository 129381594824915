<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-col cols="12" md="12 ">
              <v-text-field label="Nama" v-model="newdata.name"></v-text-field>
              <v-text-field label="Link URL" v-model="newdata.url"></v-text-field>
              <v-text-field label="Image URL" v-model="newdata.image"></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="opsitable.search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getUsers(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="users"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totaluser"
      :loading="loading"
      @update:options="getUsers(opsitable)">
      <template v-slot:item.createat="{ item }">
        <div class="ma-2">
          {{formatDate(item.createat)}}
        </div>
      </template>
      <template v-slot:item.updateat="{ item }">
        <div class="ma-2">
          {{formatDate(item.updateat)}}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'Users',
  data: ()=>({
    search: '',
    tab: '',
    newdata: {},
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    state: 0,
    titledialog: "Tambah Banner",
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Nama',
        value: 'nama'
      },
      {
        text: 'Phone',
        value: 'phone'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Create At',
        value: 'createat'
      },
      {
        text: 'Update At',
        value: 'updateat'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {

  },
  computed: {
    ...mapState('UserStore',['loading','dialog','users','totaluser'])
  },
  methods: {
    ...mapActions('UserStore',['setdialog','getUsers','setloading']),
    simpan(){

    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    openedit(data){
      //this.state=0;
      //this.newdata=data;
      //this.titledialog="Edit User"
      //this.setdialog(true)
      this.$router.push('/user/'+data.id)
    }
  }
}
</script>

<style scoped>

</style>
