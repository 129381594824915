<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog scrollable v-model="dialog" persistent width="600">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-col cols="12" md="12 ">
              <v-text-field label="Judul" v-model="newdata.title"></v-text-field>
              <v-text-field label="SubJudul" v-model="newdata.subtitle"></v-text-field>
              <quill-editor
                class="quill"
                :content="newdata.content"
                @change="onEditorChange($event)"
              >

              </quill-editor>
              <ImageNewsUpload label="Image Berita" :value=image />
              <v-switch
                flat icon class="ml-4"
                inset
                v-model="newdata.status"
                label="Status"/>
            </v-col>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
        <v-icon dark>mdi-plus</v-icon>Tambah
      </v-btn>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getNews(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="news"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalnews"
      :loading="loading"
      @update:options="getNews(opsitable)">
      <template v-slot:item.tumb="{ item }">
        <div class="pa-4">
          <v-img max-height="100" max-width="100" :src="item.tumb"/>
        </div>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="pa-4">
          <v-img max-height="200" max-width="200" :src="item.image"/>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.updateat="{ item }">
        <div class="ma-2">
          {{formatDate(item.updateat)}}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageNewsUpload from '@/components/ImageNewsUpload'
import moment from 'moment'
export default {
  name: 'News',
  components: {
    ImageNewsUpload,
    quillEditor
  },
  data: ()=>({
    search: '',
    tab: '',
    newdata: {
      title:'',
      subtitle:'',
      content: 'okeoke',
      tumb: '',
      image: '',
      status:0,
    },
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    state: 0,
    titledialog: "Tambah Berita",
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Judul',
        value: 'title'
      },
      {
        text: 'Sub Judul',
        value: 'subtitle'
      },
      {
        text: 'Tumb',
        value: 'tumb'
      },
      {
        text: 'Image',
        value: 'image'
      },
      {
        text: 'Isi',
        value: 'content'
      },
      {
        text: 'status',
        value: 'status'
      },
      {
        text: 'UpdateAt',
        value: 'updateat'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
    this.setImage('');
    this.setTumb('');
  },
  computed: {
    ...mapState('NewsStore',['loading','dialog','news','totalnews','tumb','image'])
  },
  methods: {
    ...mapActions('NewsStore',['setdialog','getNews','setTumb','setImage','setloading','addNews','editNews','deleteNews']),
    simpan(){
      if(this.image!==''){
        this.newdata.image=this.image
      }
      if(this.tumb!==''){
        this.newdata.tumb=this.tumb
      }
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0;
      }
      if(this.state===1){
        this.addNews(this.newdata)
      }else{
        this.editNews(this.newdata)
      }
    },
    onEditorChange ({ html}) {
      this.newdata.content = html
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    openaddnew(){
      this.state=1;
      this.newdata={}
      this.titledialog="Tambah Berita"
      this.setdialog(true)
    },
    openedit(data){
      this.state=0;
      this.newdata=data
      this.setImage(this.newdata.image)
      this.setTumb(this.newdata.tumb)
      this.titledialog="Edit Berita"
      this.setdialog(true)
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteNews(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>
.quill {
  height: 300px;
  padding-bottom: 95px;
}

.imgslidelist {
  width: 300px;
  height: 100px;
}

.tdlist {
  padding: 10px !important;
}
</style>
