<template>
  <div class="mx-auto align-content-center text-center ma-4" v-if="loading">
    <v-progress-circular class="align-content-center" size="50" rotate="50" indeterminate color="deep-orange"></v-progress-circular>
    <h3 class="mt-4">Sedang membuat order mohon ditunggu....</h3>
  </div>
</template>

<script>
export default {
  name: 'LoadingPayment',
  props: ['loading']
}
</script>

<style scoped>

</style>
