import { BannerService } from '@/services/banner.service'

const state = {
  loading:false,
  dialog:false,
  banners: [],
  totalbanner:0,
  overlay: false,
  bannerimage:'',
}

const actions = {
  setloading({ commit },data){
    commit('setloading',data)
  },
  setImage ({ commit }, image) {
    commit('setimage', image)
  },
  setdialog({ commit },diag) {
    if(!diag){
      commit('setimage', '')
    }
    commit('setdialog',diag)
  },
  getBanner({dispatch, commit },opt) {
    commit('setloading',true)
    console.log(opt)
    BannerService.getBanner(opt).then(value => {
      commit('setbannerdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addBanner({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    BannerService.addBanner(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })

    }).catch(e=>{
      console.log(e)
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editBanner({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    BannerService.editBanner(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      console.log(reason)
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  HapusBanner({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    BannerService.deleteBanner(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      console.log(reason)
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }

}



const mutations = {
  setloading(state,s){
    state.loading=s;
    state.overlay=s;
  },
  setimage(state,s){
    state.bannerimage=s;
  },
  setdialog(state,s){
    state.dialog=s;
  },
  setbannerdata(state,s){
    state.banners=[];
    state.banners=s.data.content
    state.totalbanner=s.data.totalElements
    state.loading=false;
    state.overlay=false;
  }
}

export const Banner = {
  namespaced: true,
  state,
  actions,
  mutations
}
