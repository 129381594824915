<template>
  <div class="">
    <v-tabs  v-model="tab" >
      <v-tab>Category</v-tab>
      <v-tab>Produk</v-tab>
      <v-tab-item>
        <SidompulPanel/>
      </v-tab-item>
      <v-tab-item>
        <SidompulProducts/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SidompulPanel from "@/components/moduleoperator/SidompulPanel";
import SidompulProducts from "@/components/moduleoperator/SidompulProducts";
export default {
  name: "SidompulMain",
  components: {SidompulProducts, SidompulPanel},
  data: ()=>({
    tab:null
  })
}
</script>

<style scoped>

</style>
