import { UserService } from '@/services/user.service'
import router from '@/router'

const state = {
  loading:false,
  dialog:false,
  dialogedit:false,
  overlay:false,
  users:[],
  dialoguserdetail:false,
  totaluser:0,
  userdetail:{
    user: {
      roles: []
    }
  }
}
const actions = {
  setloading({ commit },data){
    commit('setloading',data)
  },
  setdialogedit({ commit },diag) {
    commit('setdialogedit',diag)
  },
  setdialog({ commit },diag) {
    commit('setdialog',diag)
  },
  setdialoguserdetail({ commit },diag) {
    commit('setdialoguserdetail',diag)
  },
  gantipassword ({ dispatch, commit }, { pass }) {
    commit('setloading', true)
    UserService.gantipassword(pass)
      .then(data => {
        console.log(data)
        commit('setloading', false)
        router.push('/login')
        dispatch('alert/success', 'Ganti Password Successfully', { root: true })
      }).catch(error => {
      commit('setloading', false)
      dispatch('alert/error', error, { root: true })
    })
  },
  doeditUser({dispatch,commit},user){
    commit('setloading',true)
    let us=user
    UserService.editUser(user).then(value => {
      console.log(value)
      commit('setdialogedit',false)
      commit('setloading',false)
      dispatch('alert/success', 'Update OK!', { root: true })
      UserService.getUserDetail(us.id).then(value => {
        commit('setuserdetail',value)
        commit('setloading',false)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
      dispatch('alert/error', reason, { root: true })
    });
  },
  enableWallet({commit},opt){
    commit('setloading',true)
    UserService.enablewallet(opt).then(value => {
      console.log(value)
      commit('setloading',false)
      let opsitable= {
        search:'',
        itemsPerPage: 10,
        page:1,
        sortBy: ["id"],
      };
      UserService.getUsers(opsitable).then(value => {
        commit('setdata',value)
        commit('setloading',false)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  disableWallet({commit},opt){
    commit('setloading',true)
    UserService.disablewallet(opt).then(value => {
      console.log(value)
      commit('setloading',false)
      let opsitable= {
        search:'',
        itemsPerPage: 10,
        page:1,
        sortBy: ["id"],
      };
      UserService.getUsers(opsitable).then(value => {
        commit('setdata',value)
        commit('setloading',false)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  disableUser({commit},opt){
    commit('setloading',true)
    UserService.disableUser(opt).then(value => {
      console.log(value)
      commit('setloading',false)
      let opsitable= {
          search:'',
          itemsPerPage: 10,
          page:1,
          sortBy: ["id"],
        };
      UserService.getUsers(opsitable).then(value => {
        commit('setdata',value)
        commit('setloading',false)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  enableUser({commit},opt){
    commit('setloading',true)
    UserService.enableUser(opt).then(value => {
      console.log(value)
      commit('setloading',false)
      let opsitable= {
        search:'',
        itemsPerPage: 10,
        page:1,
        sortBy: ["id"],
      };
      UserService.getUsers(opsitable).then(value => {
        commit('setdata',value)
        commit('setloading',false)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  addRoleUser({commit},opt){
    commit('setloading',true)
    UserService.addRoleUser(opt).then(value => {
      console.log(value)
      UserService.getUserDetail(opt.userid).then(valuex => {
        commit('setloading',false)
        commit('setuserdetail',valuex)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  deleteRoleUser({commit},opt){
    commit('setloading',true)
    UserService.deleteRoleUser(opt).then(value => {
      console.log(value)
      UserService.getUserDetail(opt.userid).then(valuex => {
        commit('setloading',false)
        commit('setuserdetail',valuex)
      }).catch(reason => {
        console.log(reason)
        commit('setloading',false)
      });
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  getUsers({commit},opt){
    commit('setloading',true)
    UserService.getUsers(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
  getUserDetail({commit},userid){
    commit('clearuserdetail')
    commit('setloading',true)
    UserService.getUserDetail(userid).then(value => {
      commit('setloading',false)
      commit('setuserdetail',value)
    }).catch(reason => {
      console.log(reason)
      commit('setloading',false)
    });
  },
}
const mutations = {
  setloading(state,s){
    state.loading=s;
  },
  setdialog(state,s){
    state.dialog=s;
  },
  setdialogedit(state,s){
    state.dialogedit=s;
  },
  setdialoguserdetail(state,s){
    state.dialoguserdetail=s;

  },
  clearuserdetail(state){
    state.userdetail={
      user:{
        roles:[]
      }
    }
  },
  setuserdetail(state,user){
    console.log(user.data)
    state.userdetail=user.data;
  },
  setdata(state,s){
    state.users=[];
    state.users=s.data.content;
    state.totaluser=s.data.totalElements
    state.loading=false;
    state.overlay=false;
  }
}


export const UserStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
