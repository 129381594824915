import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl
export const BannerService = {
  getBanner,
  addBanner,
  editBanner,
  deleteBanner
}

function getBanner (opt) {
  //console.log(opt)
  var sort="id"
  var sorder="DESC"
  if(opt.sortBy){
   // console.log(opt.sortBy)
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/banner?page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function addBanner (postdata) {
  return fetch(API_URL + '/api/admin/v1/banner', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function editBanner (postdata) {
  return fetch(API_URL + '/api/admin/v1/banner', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function deleteBanner (postdata) {
  return fetch(API_URL + '/api/admin/v1/banner/'+postdata, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
