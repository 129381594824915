var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialogorder),callback:function ($$v) {_vm.dialogorder=$$v},expression:"dialogorder"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","dense":"","cad":""}},[_c('v-toolbar-title',{staticClass:"subheading"},[_vm._v("DetailOrder")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setdialogorder(false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"text-xs-left"},[_c('div',{staticClass:"mt-4"}),_c('v-list',[_c('v-list-item-group',[_vm._l((_vm.detailorder),function(order,index){return [_c('v-list-item',{key:index,attrs:{"ripple":"","append":""}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":order.preview}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},domProps:{"textContent":_vm._s(order.nama)}})],1),_c('v-list-item-action',[(order.status>0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openpage(order.asset)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1)],1),_c('v-divider',{key:(index + "-divider")})]})],2)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","dark":"","color":"red"},on:{"click":function($event){return _vm.setdialogorder(false)}}},[_vm._v("Tutup")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.orders,"item-key":"id","options":_vm.opsitable,"server-items-length":_vm.totalorder,"loading":_vm.loading},on:{"update:options":[function($event){_vm.opsitable=$event},function($event){return _vm.getOrders(_vm.opsitable)}]},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-img',{attrs:{"max-height":"36","max-width":"36","src":item.icon}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-4 text-xs-left"},[_c('v-chip',{attrs:{"color":_vm.colorStatus(item.status),"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.statusText(item.status))+" ")])],1)]}},{key:"item.createat",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm.formatDate(item.createat))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatAngka(item.amount))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-4 text-xs-left"},[_c('v-btn',{staticClass:"primary",attrs:{"x-small":"","fab":"","text":""},nativeOn:{"click":function($event){return _vm.openedit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }