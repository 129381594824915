
import { SettingService } from '@/services/setting.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  dialogimageshare: false,
  maintenance_switch:false,
  image:'',
  appsetting: { "id": 1,
    "registerquota": 100, "pg": 0, "reghash": 0, "trfsaldo": 0, "topupwallet": 0, "qrischannel": 0, "qrisbeliemoney": 0, "maxbeliemoney": 0, "actuser": 0,
    "maintenance": 0, "transpotmode": 0, "defaultupline": "-", "defaultpriceplan": 1, "senderotp": 0, "vafee": 0, "vamin": 0,
    "qrisfee": 0, "qrismin": 0, "ovofee": 0, "ovomin": 0, "alfafee": 0, "alfamin": 0, "zopimkey": "", "primarycolor": "#031751",
    "digiposterminal":0,
    "isimpleterminal":0,
    "ritaterminal":0,
    "sidompulappterminal":0,
    "accentcolor": "#f28e02", enableva:0,enablealfa:0,enableovo:0,enableqris:0 }
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  setdialogimageshare ({ commit }, diag) {
    commit('setdialogimageshare', diag)
  },
  setImage ({ commit }, image) {
    commit('setimage', image)
  },
  setImageShare ({ commit }, image) {
    commit('setimageshare', image)
  },
  getSetting({dispatch, commit }) {
    commit('setloading',true)
    SettingService.getSetting().then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  updateSetting({ dispatch,commit },opt) {
    commit('setloading',true)
    SettingService.simpanSetting(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      commit('setdialogimageshare',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  maintenance({ dispatch,commit },mode) {
    commit('setloading',true)
    SettingService.setMaintenance(mode).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e=>{
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdialogimageshare (state, s) {
    state.dialogimageshare = s
  },
  setdata (state, s) {
    state.appsetting = s.data
    state.maintenance_switch = s.data.maintenance
    state.loading = false
    state.overlay = false
  },
  setimage (state, s) {
    state.appsetting.applogo=s
    state.image = s
  },
  setimageshare (state, s) {
    state.appsetting.imagesharerefferal=s
    state.image = s
  },
}

export const SettingStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
