<template>
    <nav>
      <v-app-bar dense color="primary" dark clipped-left  app>
      <v-dialog v-model="dialog" persistent width="500">
      <v-card elevation="0">
        <v-toolbar elevation="0" color="primary" dark text dense cad>
          <v-toolbar-title class="subheading">Kirim Push Notifikasi</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeform()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
              <v-layout fluid row wrap>
                <v-flex lg12 sm6 xs12 class="pt-0 pb-0">
                  <v-text-field label="Judul" name="judul" v-model="push.title"></v-text-field>
                </v-flex>

                <v-flex lg12 sm6 xs12 class="pt-0 pb-0">
                  <v-text-field label="Isi Pesan" name="pesan" v-model="push.msg"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="closeform()">Tutup</v-btn>
          <v-btn text :disabled="!valid" color="success" @click="sendpush(push)">Kirim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-app-bar-nav-icon @click.stop="handleDrawerToggle"></v-app-bar-nav-icon>
      <img src="../assets/irswhite.png" class="logox" height="30" width="50" alt="irma">
      <v-toolbar-title class="white--text logotitle"></v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="align-self-center mt-6 mr-4">
        <v-switch color="red" flat icon v-model="maintenance_switch2" inset :label="`Maintenance  `"></v-switch>
      </div>
      <v-btn icon @click="openformpush()">
      <v-icon>mdi-send</v-icon>
    </v-btn>
    <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
      <template v-slot:activator="{ on }">
      <v-btn icon large text slot="activator" v-on="on">
        <v-avatar size="30px">
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
      </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item,index) in items"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
  </nav>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
  name: 'app-toolbar',
  components: { },
  data: () => ({
    dialog: false,
    valid: true,
    cancel: true,
    initdata: true,
    maintenance_switch2: false,
    push: { title: '', msg: '' },
    items: [
      {
        icon: 'mdi-lock-outline',
        href: '#',
        title: 'Change Password',
        click: () => {
          window.getApp.$emit('APP_CHANNGE_PASSWORD')
        }
      },
      {
        icon: 'mdi-exit-to-app',
        href: '#',
        title: 'Logout',
        click: () => {
          window.getApp.$emit('APP_LOGOUT')
        }
      }
    ]
  }),
  computed: {
    ...mapState('SettingStore', ['maintenance_switch']),
  },
  watch: {
    maintenance_switch: function (val) {
      console.log("watch: " + val);
      if (this.initdata) {
        this.initdata = false;
      }
      this.maintenance_switch2 = val;
    },
    maintenance_switch2:function (val) {
      if(!this.cancel){
        this.setMaintenanceMode(val)
      }else{
        if(this.initdata){
          this.initdata=false;
          this.setMaintenanceMode(val)
        }else{
          this.cancel=false;
        }
      }
    }
  },
  mounted () {
    this.getSetting()
  },
  methods: {
    ...mapActions('SettingStore',['getSetting']),
    handleDrawerToggle () {
      window.getApp.$emit('APP_DRAWER_TOGGLED')
    },
    openformpush () {
      this.dialog = true
    },
    closeform () {
      this.dialog = false
    },
    sendpush (data) {
      this.$store.dispatch('PushStore/sendbcpush', data)
      this.push.title = ''
      this.push.msg = ''
      this.dialog = false
    },
    setMaintenanceMode() {
      console.log(this.maintenance_switch2);
      if(this.maintenance_switch2 ){
        this.$confirm('Yakin akan set maintenance, pesan sedang maintenance akan tampil saat applikasi dibuka', { color: 'red'}).then(res => {
          if (res) {
            this.$store.dispatch('SettingStore/maintenance', 1);
          }else{
            this.cancel =true;
            this.maintenance_switch2=false;
          }
        })
      }else{
        this.$confirm('Yakin akan non aktifkan maintenance mode?', { color: 'green'}).then(res => {
          console.log(res);
          if (res) {
            this.$store.dispatch('SettingStore/maintenance', 0);
          }else{
            this.cancel =true;
            this.maintenance_switch2=true;
          }
        })
      }

    },
  }
}
</script>

<style>
.logotitle {
  margin-left: 8px;
}
.logox {
  margin-left: 8px;
}
</style>
