
import { IRSXStoreService } from '@/services/irsxstore.service'

const state = {
  dialogorder:false,
  loadingpayment:false,
  dialogneworder:false,
  donepayment:false,
  paymentresponse:{},
  selectedcategory:null,
  loading: false,
  dialog: false,
  overlay: false,
  products: [],
  categories: [],
  cart: [],
  totalproducts: 0,
  totalcategories:0,
  orders: [],
  totalorder: 0,
  detailorder: [],
  selectedproduct: {}
}
const actions = {
  setselectedproduct ({ commit },produk) {
    commit('setselectedproduct',produk)
  },
  setselectedcategory ({ commit },cat) {
    commit('setselectedcategory',cat)
  },
  cleanupOrder ({ commit }) {
    commit('cleanuporder')
  },
  setloadingpayment ({ commit }, data) {
    commit('setloadingpayment', data)
  },
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialogneworder ({ commit }, data) {
    commit('setdialogneworder', data)
  },
  setdialogorder ({ commit }, data) {
    commit('setdialogorder', data)
  },
  deleteItemCart ({ commit },index) {
    commit('deleteitemcart',index)
  },
  setincartbyid ({ commit },index) {
    commit('setinchartbyid',index)
  },
  setincart ({ commit },index) {
    commit('setincart',index)
  },
  clearchart ({ commit }) {
    commit('clearcart')
  },
  addtochart ({ commit }, item) {
    commit('adddatacart', item)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  orderRequest({dispatch, commit },opt) {
    commit('setloading',true)
    commit('setloadingpayment',true)
    IRSXStoreService.orderRequest(opt).then(value => {
      commit('setpaymentresponse',value)
      commit('setloading',false)
      commit('setdonepayment',true)
      commit('setloadingpayment',false)
      commit('clearcart',false)
    }).catch(reason => {
      commit('setloadingpayment',false)
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getOrderdetail({dispatch, commit },id) {
    commit('setloading',true)
    IRSXStoreService.getOrderDetail(id).then(value => {
      commit('setdatadetailorder',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getOrders({dispatch, commit },opt) {
    commit('setloading',true)
    IRSXStoreService.getAllOrder(opt).then(value => {
      commit('setdataorder',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getProducts({dispatch, commit },opt) {
    commit('setloading',true)
    IRSXStoreService.getProducts(opt).then(value => {
      commit('setdataproducts',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getCategories({dispatch, commit },opt) {
    commit('setloading',true)
    IRSXStoreService.getCategories(opt).then(value => {
      commit('setdatacategories',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  cleanuporder (state) {
    state.donepayment = false
    state.loadingpayment = false
    state.paymentresponse = false
  },
  setselectedproduct (state, s) {
    state.selectedproduct = s
  },
  setselectedcategory (state, s) {
    state.selectedcategory = s
  },
  setloading (state, s) {
    state.loading = s
  },
  setdonepayment (state, s) {
    state.donepayment = s
  },
  setpaymentresponse (state, s) {
    state.paymentresponse = s
  },
  setloadingpayment (state, s) {
    state.loadingpayment = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdialogneworder (state, s) {
    state.dialogneworder = s
  },
  setdialogorder (state, s) {
    state.dialogorder = s
  },
  adddatacart (state, s) {
    state.cart.push(s)
  },
  setinchartbyid(state,id){
    state.products.forEach(function (element) {
      if(element.id === id){
        element.incart=true
      }
    });
  },
  deleteitemcart(state,index){
    console.log(index)
    let p = state.cart[index]
    state.products.forEach(function (element) {
      if(element.id === p.id){
        element.incart=false
      }
    });
    state.cart.splice(index,1)
  },
  setincart(state,index){
    state.products[index].incart=true
  },
  clearcart(state){
    state.cart = []
    state.products.forEach(function (element) {
      element.incart = false;
    });
  },
  setdataproducts (state, s) {
    state.products = []
    s.data.forEach(function (element) {
      element.incart = false;
    });
    state.products = s.data
    state.totalproducts = s.data.length
    state.loading = false
    state.overlay = false
  },
  setdatacategories (state, s) {
    state.categories = []
   // s.data.forEach(function (element) {
   //   element.jumlah = 10;
   // });
    state.categories = s.data
    state.totalcategories = s.data.length
    state.loading = false
    state.overlay = false
  },
  setdataorder (state, s) {
    state.orders = []
    if(typeof s.data !== 'undefined'){
      state.orders = s.data
      state.totalorder = s.data.length
    }
    state.loading = false
    state.overlay = false
  },
  setdatadetailorder (state, s) {
    state.detailorder = []
    state.detailorder = s.data
    state.loading = false
    state.overlay = false
  },
}

export const IRSXStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
