<template>
  <div class="ma-2">
    <div class="mb-4"><h2>STRUK BUILDER</h2></div>
    <v-divider></v-divider>
    <v-layout row wrap class="mt-4">
      <v-flex lg8  sm12 xs12>
        <div class="mr-4 ml-4 mb-2">
          <v-row>
            <v-autocomplete
              label="Terminal"
              placeholder="Pilih Terminal"
              item-text="namaterminal"
              item-value="id"
              :loading="loading"
              @change="terminalchange"
              v-model="newdata.idterminal"
              :items="terminalh2h">
            </v-autocomplete>

            <v-autocomplete
              label="Produk"
              placeholder="Pilih Produk"
              item-text="produk.namaproduk"
              item-value="produk.id"
              v-model="newdata.idproduk"
              :items="produkterminal">
            </v-autocomplete>
          </v-row>
        </div>
        <v-textarea  outlined label="TextReplay" v-model="sourcetext"></v-textarea>
        <v-text-field label="Pattern" :error="!regexOK" outlined v-model="newdata.pattern"></v-text-field>
        <v-layout row wrap>
          <v-flex lg4  sm12 xs12>
            <div class="ml-6 mr-6 mb-8 mt-4">
              <v-row>
                <div v-for="(item,index) in grouptext" :key="index">
                  <v-chip pill  draggable class="pa-2 ma-1 primary">{{item.name}}={{item.value}}</v-chip>
                </div>
              </v-row>
            </div>
          </v-flex>
          <v-flex lg8  sm12 xs12>
            <div class="mr-4">
              <v-textarea @click:prepend="generatetemplatestruk" prepend-icon="mdi-arrange-send-backward" @keydown.tab.prevent="tabber($event)" outlined label="Struk Format" v-model="newdata.struk"></v-textarea>
            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-row class="mb-8 mr-1">
          <v-spacer></v-spacer>
          <v-btn @click.native="simpan" class="primary mt-4"><v-icon>mdi-content-save</v-icon> Simpan</v-btn>
        </v-row>
      </v-flex>
      <v-flex lg4  sm12 xs12>
        <v-card elevation="1" class="mr-4 ml-4">
          <div class="justify-center text-center pt-2 pb-2">Preview Struk</div>
          <v-divider></v-divider>
          <v-card-text style="background-color: rgb(245, 245, 245)">
            <div style="background-color: rgb(245, 245, 245); font-weight: bold">
              <code>{{hasilstruk}}</code>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
//  (?<x>.*)
import moment from 'moment'
import pad from 'utils-pad-string'
import S from 'string'
import Utils from '@/plugins/utils'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CetakStrukBuilder',
  data: () => ({
    state:0,
    regexOK:false,
    user: localStorage.getItem("user"),
    //sourcetext:'{SUCCESS:TRUE,RC:0,TYPE:6,DETAIL:RC:00@PLNPAY@IDPEL:521020807046@NAMA:SIGIT PURWANTO@TD:R1/450@BLN:202107@RPTAG:32894@REFF:0SOM21G301739791074             @BALANCE:5162228146@SM:13867 - 14008@JML:1,TOTALBAYAR:34894,ADMIINBANK:2000,TRXID:57993925,TUJUAN:521020807046,MSG:27}',
    //fpatern:'IDPEL:(?<idpel>\\d*)@NAMA:(?<nama>\\D*)@TD:(?<td>\\D*\\d/\\d*)@BLN:(?<bln>\\d*)@RPTAG:(?<rptag>\\d*)@REFF:(?<reff>\\w*\\s*)@BALANCE:(?<saldo>\\d*)@SM:(?<sm>\\d*\\s-\\s\\d*)@JML:(?<jml>\\d),TOTALBAYAR:(?<total>\\d*),ADMIINBANK:(?<admin>\\d*)',
    sourcetext:'',
    ffformatstruk: '<namars>\n' +
      '<alamatrs>\n' +
      '<phoners>\n' +
      '===================================\n' +
      'STRUK PEMBAYARAN TELKOM\n' +
      '\n' +
      'Waktu\t\t: <waktu> \n' +
      'IdPel\t\t: <idpel>\n' +
      'Nama\t\t: <nama>\n' +
      'Bln\t\t\t: <bln>\n' +
      'reff\t\t: <reff>\n' +
      'RpTag\t\t: <rptag>\n' +
      'Admin\t\t: <admin>\n' +
      '------------------------- +\n' +
      'TotalBayar\t: <total>\n' +
      '\n' +
      'Simpan Struk ini sebagai bukti yang sah\n' +
      'TERIMAKASIH\t',
    hasilstruk:'',
    namedgroup: [],
    grouptext:[],
    newdata:{
      idterminal:'',
      idproduk:0,
      pattern: '',
      struk: ''
    }
  }),
  mounted () {
    this.getTerminalH2h();
    if (!this.$route.params.id) {
      this.state=0 // new
      //this.newdata.struk=this.ffformatstruk
      this.hasilstruk=this.formatlocal(this.newdata.struk);
      this.parseAll()
    }else{
      this.state=1 // edit
      this.getStrukbyid(this.$route.params.id)
    }
  },
  watch: {
    ffformatstruk: function (val){
      this.getNamedGroup()
      var regex = new RegExp(val.pattern, "g");
      var match= regex.exec(this.sourcetext)
      this.hasilstruk=this.formatlocal(this.newdata.struk);
      this.hasilstruk=this.parsematch(match)
    },
    strukdata: function (val){
        console.log(val)
        this.newdata=val
        this.getProdukTerminalByIdT({idterminal:val.idterminal,search:''})
    },
    sourcetext: function (){
        this.parseAll()
    },
    pat: function (){
        this.parseAll()
    },
    fstruk:function (){
      this.parseAll()
    }
  },
  computed: {
    ...mapState('ProductStore',['terminalh2h','produkterminal']),
    ...mapState('StrukStore',['strukdata','loading']),
    ...mapState('authentication',['user']),
    loadingproduk: function (){
      return this.$store.state['ProductStore/loading']
    },
    pat: function(){
      return this.newdata.pattern
    },
    fstruk: function(){
      return this.newdata.struk
    }
  },
  methods: {
    ...mapActions('ProductStore',['getTerminalH2h','getProdukTerminalByIdT']),
    ...mapActions('StrukStore',['addStruk','editStruk','getStrukbyid']),
    parseAll(){
      this.getNamedGroup()
      try {
        var regex = new RegExp(this.newdata.pattern, "g");
        var match= regex.exec(this.sourcetext)
        this.hasilstruk=this.formatlocal(this.newdata.struk);
        this.hasilstruk=this.parsematch(match).trim()
      }catch (e) {
        //console.log(e)
      }
    },
    tabber (event){
      if (event) {
        event.preventDefault()
        let startText = this.newdata.struk.slice(0, event.target.selectionStart)
        let endText =  this.newdata.struk.slice(event.target.selectionStart)
        this.newdata.struk = `${startText}\t${endText}`
        event.target.selectionEnd = event.target.selectionStart + 1
      }
    },
    getNamedGroup(){
      this.namedgroup=[]
      var regex=/\(\?<([a-zA-Z][a-zA-Z0-9]*)>/g
      var match = regex.exec(this.newdata.pattern)
      while (match != null) {
        this.namedgroup.push(match[1])
        match = regex.exec(this.newdata.pattern);
      }
    },
    parsematch(match){
      let a=this.hasilstruk
      let jasaloket=2000
      let denom=100000
      let sn='02102020203123213'
      a=a.replace('<jasaloket>','Rp'+pad(this.formatAngka(jasaloket),10,{'lpad':'.'}))
      a=a.replace('<denom>','Rp'+pad(this.formatAngka(denom),10,{'lpad':'.'}))
      this.grouptext=[]
      if(match!=null){
        this.regexOK=true
        for (let i = 0; i < this.namedgroup.length; i++) {
          this.grouptext.push({name:'<'+this.namedgroup[i]+'>',value:match.groups[this.namedgroup[i]].trim()})
          if(S(this.namedgroup[i]).startsWith('rp') || S(this.namedgroup[i]).contains('totalbayar')||S(this.namedgroup[i]).contains('total') || S(this.namedgroup[i]).contains('admin') || S(this.namedgroup[i]).contains('tag')){
            a=a.replace('<'+this.namedgroup[i]+'>','Rp'+pad(this.formatAngka(match.groups[this.namedgroup[i]].trim()),10,{'lpad':'.'}))
          }else{
            a=a.replace('<'+this.namedgroup[i]+'>',match.groups[this.namedgroup[i]].trim())
          }
        }
      }else{
        this.regexOK=false
      }
      let x = denom+jasaloket;
      a=a.replace('<denom+jasaloket>','Rp'+pad(this.formatAngka(x),10,{'lpad':'.'}))
      a=a.replace('<sn>',sn)
      a=a.replace('<idrs>',"ID0001")
      a=a.replace("<namars>",'TEST NAMA')
      a=a.replace("<alamatrs>",'Jln Maju Makmur No 10,Denpasar BALI')
      a=a.replace("<phoners>",'081xxxx9999')
      return a;
    },
    formatlocal(input){
      let dt = moment().format('DD/MM/YYYY HH:mm:ss')
      return input.replace("<waktu>",dt)
    },
    formatAngka(val){
      return Utils.formatAngka(val)
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    generatetemplatestruk(){
      this.$confirm('Generate Contoh Struk ?', { color: 'primary',title:'Konfirmasi',icon:'fa-question' }).then(res => {
        if (res) {
          this.newdata.struk=this.ffformatstruk
        }
      })
    },
    terminalchange(val){
      console.log(val)
      this.getProdukTerminalByIdT({idterminal:val,search:''})
    },
    simpan(){
      if(this.newdata.idterminal===0 || this.newdata.idproduk===0) return
      this.$confirm('Yakin akan Simpan ?', { color: 'primary',title:'Simpan',icon:'fa-question' }).then(res => {
        if (res) {
          if(this.state===0){
            this.addStruk(this.newdata)
          }else{
            this.editStruk(this.newdata)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
pre, code{
  white-space: pre-wrap;
  font-weight: bold !important;
  font-size: 14px !important;
  font-family: monospace;
  background-color:rgb(245, 245, 245) !important;
}
</style>
