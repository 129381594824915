
import { ApplikasiService } from '@/services/app.service'

const state = {
  loading:false,
  dialog:false,
  aplikasi: [],
  totalaplikasi:0,
  overlay: false,
}

const actions = {
  setloading({ commit },data){
    commit('setloading',data)
  },
  setImage ({ commit }, image) {
    commit('setimage', image)
  },
  setdialog({ commit },diag) {
    if(!diag){
      commit('setimage', '')
    }
    commit('setdialog',diag)
  },
  getaplikasi({dispatch, commit },opt) {
    commit('setloading',true)
    console.log(opt)
    ApplikasiService.getAplikasi(opt).then(value => {
      commit('setbannerdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addAplikasi({ dispatch,commit },opt) {
    commit('setloading',true)
    ApplikasiService.addAplikasi(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })

    }).catch(e=>{
      console.log(e)
      dispatch('alert/error', e, { root: true })
      commit('setloading',false)
    })
  },
  editaplikasi({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    ApplikasiService.editaplikasi(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      console.log(reason)
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  hapusbanner({ dispatch,commit },opt) {
    commit('setloading',true)
    console.log(opt)
    ApplikasiService.deleteAlikasi(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      console.log(reason)
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  }
}

const mutations = {
  setloading(state,s){
    state.loading=s;
    state.overlay=s;
  },
  setimage(state,s){
    state.bannerimage=s;
  },
  setdialog(state,s){
    state.dialog=s;
  },
  setbannerdata(state,s){
    state.aplikasi=[];
    state.aplikasi=s.data.content
    state.totalaplikasi=s.data.totalElements
    state.loading=false;
    state.overlay=false;
  }
}

export const AplikasiStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
