<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-col cols="12" md="12 ">
              <v-text-field readonly label="User" v-model="userText"></v-text-field>
              <v-text-field readonly label="Bank" v-model="newdata.bankcode"></v-text-field>
              <v-text-field readonly label="No VA" v-model="newdata.nova"></v-text-field>
              <v-text-field readonly label="Nominal" v-model="newdata.nominal"></v-text-field>
              <v-switch
                flat icon class="ml-4"
                inset
                v-model="newdata.status"
                label="Status"/>
            </v-col>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="opsitable.search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <div class="mt-6">
        <v-select
          label="Status"
          item-text="label"
          item-value="id"
          v-model="opsitable.status"
          :items="statuses"
        ></v-select>
      </div>
      <div class="mt-6">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="opsitable.awal"
              label="Tanggal Awal"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="opsitable.awal" @input="menu1 = false"></v-date-picker>
        </v-menu>
      </div>
      <div class="mt-6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="opsitable.akhir"
              label="Tanggal Akhir"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="opsitable.akhir" @input="menu2 = false"></v-date-picker>
        </v-menu>
      </div>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getListVa(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="va"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalva"
      :loading="loading"
      @update:options="getListVa(opsitable)">
      <template v-slot:item.user="{ item }">
        {{item.user.masterresellers[0].idreseller+ '- ' +item.user.masterresellers[0].namareseller}}
      </template>
      <template v-slot:item.tumb="{ item }">
        <div class="pa-4">
          <v-img max-height="100" max-width="100" :src="item.tumb"/>
        </div>
      </template>
      <template v-slot:item.image="{ item }">
        <div class="pa-4">
          <v-img max-height="200" max-width="200" :src="item.image"/>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.createat="{ item }">
        <div class="ma-2">
          {{formatDate(item.createat)}}
        </div>
      </template>
      <template v-slot:item.updateat="{ item }">
        <div class="ma-2">
          {{formatDate(item.updateat)}}
        </div>
      </template>
      <template v-slot:item.nominal="{ item }">
        <div class="ma-2">
          {{formatAngka(item.nominal)}}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import Utils from '@/plugins/utils'

export default {
  name: 'VirtualAccount',
  data: ()=>({
    search: '',
    newdata: {},
    modaldate: false,
    menu1: false,
    menu2: false,
    date: new Date().toISOString().substr(0, 10),
    opsitable: {
      search: '',
      itemsPerPage: 10,
      page: 1,
      sortBy: ["id"],
      status:-1,
      awal: moment().add(7, 'hours').toISOString().substr(0, 10),
      akhir: moment().add(7, 'hours').toISOString().substr(0, 10)
    },
    titledialog: "Edit",
    statuses: [
      {
        id: -1,
        label: 'Semua'
      },
      {
        id: 0,
        label: 'Pending'
      },
      {
        id: 1,
        label: 'Valid'
      }
    ],
    state: 0,
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Users',
        value: 'user'
      },
      {
        text: 'NoVa',
        value: 'nova'
      },
      {
        text: 'Bank',
        value: 'bankcode'
      },
      {
        text: 'Nominal',
        value: 'nominal'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'reff',
        value: 'reff'
      },
      {
        text: 'Expired',
        value: 'expdate'
      },
      {
        text: 'Create At',
        value: 'createat'
      },
      {
        text: 'Update At',
        value: 'updateat'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
  },
  computed: {
    ...mapState('PaymentGatewayStore',['loading','dialog','va','totalva']),
    userText(){
      if(typeof this.newdata.user !== 'undefined'){
        return this.newdata.user.masterresellers[0].idreseller+ ' - ' +this.newdata.user.masterresellers[0].namareseller
      }else{
        return false
      }
    }
  },
  methods: {
    ...mapActions('PaymentGatewayStore',['setdialog','getListVa','updateVA','setloading']),
    simpan(){
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0
      }
      this.updateVA(this.newdata)
    },
    colorStatus(val){
      if(val===0){
        return "orange";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Pending";
      }
      if(val===1){
        return "Sukses";
      }
    },
    formatAngka(val){
      return Utils.formatAngka(val)
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.titledialog="Edit"
      this.setdialog(true)
    },
  }
}
</script>

<style scoped>

</style>
