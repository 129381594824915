<template>
  <v-container class="fill-height back" fluid>
    <v-app-bar clipped-left app flat dark color="transparent">
      <img @click="home" class="logo" height="48" src="../assets/irswhite.png"/>
      <v-spacer/>
      <v-toolbar-items>
        <v-btn @click="login" text>Login</v-btn>
      </v-toolbar-items>
    </v-app-bar>
      <v-layout align-center justify-center>
          <v-flex xs12 sm6 md6 lg6>
             <v-card  class="elevation-2 rounded-md ml-2">
               <v-layout wrap justify-center>
                 <v-flex xs12 sm12 md12 lg6>
                   <v-layout justify-center>
                     <div style="" class="primary layout align-center hidden-sm-and-down pa-10 rounded-md">
                       <img name="ok" class="logo contain" height="250" src="../assets/login.png"/>
                     </div>
                   </v-layout>
                 </v-flex>
                 <v-flex xs12 sm12 md12 lg6>
                   <h1 class="text-center primary--text mt-5">IRSX App Console</h1>
                   <v-card-text>
                     <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="handleSubmit()">
                       <v-text-field
                               v-model="email"
                               name="phone"
                               data-v-name="email"
                               type="phone"
                               required
                               label="NamaUser"
                       ></v-text-field>
                       <v-text-field
                               v-model="password"
                               data-vv-name="password"
                               name="password"
                               required
                               :rules="passwordRules"
                               label="Password"
                               type="password"
                               @keypress="keyturun"
                       ></v-text-field>
                       <v-card-actions>
                         <v-spacer></v-spacer>
                         <v-btn block class="" color="primary" @click.native="handleSubmit()" :loading="loading">
                           <v-icon dark class="mr-2">mdi-login</v-icon>
                           Login
                         </v-btn>
                       </v-card-actions>
                       <div style="height:20px"></div>
                     </v-form>
                   </v-card-text>
                 </v-flex>
               </v-layout>
             </v-card>
          </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'LoginPage',
  components: {
  },
  data () {
    return {
      params: {
      },
      renderParams: {
        width: 240,
        height: 40,
        longtitle: true
      },
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v =>
          // eslint-disable-next-line no-useless-escape
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid'
      ],
      password: '',
      passwordRules: [v => !!v || 'Password is required'],
      submitted: false,
      items: [
        {
          src: '/static/banner/banner0.jpg'
        },
        {
          src: '/static/banner/banner2.jpg'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['status', 'loading','admintersedia'])
  },
  mounted () {

  },
  watch: {
    admintersedia : function (ada) {
      if (!ada) {
       // this.$router.push('/register')
      }
    }
  },
  created () {
    // reset login status
    // this.logout()
  },
  methods: {
    ...mapActions('authentication', ['dologin', 'logout', 'cekadauser']),
    handleSubmit () {
      this.submitted = true
      const { email, password } = this
      if (email && password) {
        this.dologin({ email, password })
      }
    },
    keyturun (e) {
      if (e.code === 'Enter') {
        this.handleSubmit()
      }
    },
    forgot () {
      this.$router.push('/forgot')
    },
    home () {
      this.$router.push('/')
    },
    login () {
      this.$router.push('/login')
    },
    fblogin () {

    },
    fblogout () {

    },
    fbUserdata (data) {
      if (typeof data.response !== 'undefined') {
        //this.dologinFacebook(data.response.authResponse.accessToken)
      }
    },
    opsilogin () {
      return {
        scope: 'email,profile_pic',
        returnScopes: true
      }
    },
    onSuccess (data) {
      console.log(data.xc.id_token)
      this.dologinGoogle(data.xc.id_token)
    },
    onFailure (data) {
      console.log(data)
    },
    klikfb () {
      document.getElementById('loginfb').click()
    }
  }
}

// 805888338719-bhpm5nkk9t9o9bq1kcptkvi36ep82voj.apps.googleusercontent.com
// LWU5etvfyoDdl-FGIx-hu645

</script>

<style scoped>
  .back {
    background-image: linear-gradient(90deg,rgba(5,11,31,.8),rgba(5,11,31,.8)),url(https://irsx.sgp1.digitaloceanspaces.com/bglogintech.jpeg);
    background-size: cover;
  }
  a {
    text-decoration: none;
  }
</style>
