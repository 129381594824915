<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
        <v-icon dark>mdi-plus</v-icon>Tambah
      </v-btn>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getstruklist(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="struk"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalstruk"
      :loading="loading"
      @update:options="getstruklist(opsitable)">
      <template v-slot:item.idterminal="{ item }">
          {{item.terminal.namaterminal}}
      </template>
      <template v-slot:item.idproduk="{ item }">
        [{{item.produk.kodeproduk}}] - {{item.produk.namaproduk}}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'StrukList',
  data: () => ({
    search: '',
    tab: '',
    newdata: {
      label:'',
      info:'',
      status:0
    },
    titledialog: 'edit',
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    state: 0,
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Terminal',
        value: 'idterminal'
      },
      {
        text: 'Produk',
        value: 'idproduk'
      },
      {
        text: 'Pattern',
        value: 'pattern'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
      this.getstruklist(this.opsitable)
  },
  computed: {
    ...mapState('StrukStore',['loading','struk','totalstruk'])
  },
  methods: {
    ...mapActions('StrukStore',['getstruklist','setdialog','setloading','deleteStruk']),
    openaddnew(){
        this.$router.push('/cetakstruk/new')
    },
    openedit(data){
      this.$router.push('/cetakstruk/edit/'+data.id)
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteStruk(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
