import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'font-awesome/css/font-awesome.css'
import VuetifyConfirmPlugin from 'vuetify-confirm'
// import VueMq from 'vue-mq'
import VueTimeago from 'vue-timeago'
import VueZoomer from 'vue-zoomer'


Vue.use(VueZoomer)
Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'en'
})
/*
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})
*/
const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi' || 'fa' || 'mdiSvg'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#031751',
        secondary: colors.grey.darken1,
        accent: '#f28e02', // colors.shades.black,
        error: colors.red.accent3
      }
    }
  }
})

Vue.use(Vuetify)
Vue.use(VuetifyConfirmPlugin, {
  vuetify,
  buttonTrueText: 'Yes',
  buttonFalseText: 'No',
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  persistent: false,
  property: '$confirm'
})

export default vuetify
