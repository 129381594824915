import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export const serviceUpload = {
  newuploadimage
}

function newuploadimage (image) {
  const formData = new FormData()
  formData.append('file', image)
  return fetch(API_URL + '/api/admin/v1/upload/image', {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
