<template>
  <div>
    <v-text-field v-model="search" hide-details
                        append-icon="mdi-magnify"
                        single-line clearable rounded filled dense placeholder="Cari sesuatu..."></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Searchbar',
  data: () => ({
    search:'',
  }),
  watch: {
    search: function (val1) {
      console.log(val1)
      this.cariproduk();
    }
  },
  computed:{
    ...mapState('IRSXStore', ['loading'])
  },
  methods: {
    ...mapActions('IRSXStore', ['getProducts']),
    cariproduk(){
      console.log(this.search)
      if(!this.loading){
        if(this.search!==null && this.search.length>3){
          let opt={
            search:this.search
          }
          this.getProducts(opt)
        }
        if(this.search === null){
          this.getProducts()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
