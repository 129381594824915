
export default [
  {
    name: 'SOCKET_LOGIN',
    callback: function (data) {
      this.$socket.emit('chatlogin', data)
    }
  },
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function () {
      this.$router.push({ path: '/home' })
    }
  },
  {
    name: 'DONE_SET_ICONMENU',
    callback: function (icon) {
      this.$store.dispatch('MenuStore/setIcon', icon, { root: true })
    }
  },
  {
    name: 'DONE_SET_IMAGE_BANNER',
    callback: function (image) {
      this.$store.dispatch('Banner/setImage', image, { root: true })
    }
  },
  {
    name: 'DONE_SET_IMAGE_NEWS',
    callback: function (image) {
      this.$store.dispatch('NewsStore/setImage', image, { root: true })
    }
  },
  {
    name: 'DONE_SET_TUMB_NEWS',
    callback: function (image) {
      this.$store.dispatch('NewsStore/setTumb', image, { root: true })
    }
  },
  {
    name: 'DONE_SET_IMAGE_LOGO',
    callback: function (image) {
      this.$store.dispatch('SettingStore/setImage', image, { root: true })
    }
  },
  {
    name: 'DONE_SET_IMAGE_SHARE',
    callback: function (image) {
      this.$store.dispatch('SettingStore/setImageShare', image, { root: true })
    }
  },
  {
    name: 'DONE_SET_IMAGE_POPUP',
    callback: function (image) {
      this.$store.dispatch('PopupStore/setImage', image, { root: true })
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function () {
      // this.$store.state.message = 'Logout successfully';
      localStorage.clear()
      this.$store.dispatch('authentication/logout')
      //this.$router.push({ path: '/login' })
    }
  },
  {
    name: 'ORDER_SUCCESS',
    callback: function (data) {
      this.$store.dispatch('alert/success', 'Order Created Successfully', { root: true })
      this.$router.push({ path: '/tokensms/order/success/' + data.id })
    }
  },
  {
    name: 'APP_INFO',
    callback: function (msg) {
      console.log(msg)
      this.$store.dispatch('alert/success', 'Pesan Terkirim', { root: true })
    }
  },

  {
    name: 'APP_INFO_ERROR',
    callback: function (msg) {
      this.snackbar = {
        show: true,
        color: 'red',
        text: msg
      }
    }
  },
  {
    name: 'APP_PAGE_LOADED',
    callback: function () {
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function () {
      this.$router.push({ path: '/login' })
      this.$message.error('Token has expired')
    }
  },
  {
    name: 'APP_CHANNGE_PASSWORD',
    callback: function () {
      this.$router.push('/changepassword')
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg)
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    // @error api response data
    callback: function (msg) {
      this.snackbar = {
        show: true,
        color: 'red',
        text: msg
      }
      this.$router.push({ path: '/login' })
    }
  },
  {
    name: 'APP_RESOURCE_DELETED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg)
    }
  },
  {
    name: 'APP_RESOURCE_UPDATED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg)
    }
  },
  {
    name: 'APP_DRAWER_TOGGLED',
    callback: function () {
      this.$store.dispatch('authentication/stateDrawer')
    }
  }
]
