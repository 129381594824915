<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all">
    <v-tabs  v-model="tab">
      <v-tab>General</v-tab>
      <v-tab>Info Rekening</v-tab>
      <v-tab-item>
        <GeneralSetting/>
      </v-tab-item>
      <v-tab-item>
        <InfoRekeningPanel/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import GeneralSetting from '@/components/GeneralSetting'
import { mapActions } from 'vuex'
import InfoRekeningPanel from "@/components/InfoRekeningPanel";
export default {
  name: 'Setting',
  components: {InfoRekeningPanel, GeneralSetting },
  data: () => ({
    tab: null
  }),
  mounted () {
    this.getSetting();
  },
  methods: {
    ...mapActions('SettingStore',['getSetting','updateSetting']),
  }
}
</script>

<style scoped>

</style>
