<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-container class="mt-4">
              <v-layout fluid row wrap>
                <v-flex lg12 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Label" v-model="newdata.label"></v-text-field>
                  <v-text-field label="Label" v-model="newdata.info"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-col cols="12" md="12 ">
              <v-row align-content="center" align="center" class="ml-2">
                <v-switch
                  flat icon class="ml-4"
                  inset
                  v-model="newdata.status"
                  label="Status"/>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
        <v-icon dark>mdi-plus</v-icon>Tambah
      </v-btn>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getRunningtext(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="runningtext"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalrunningtext"
      :loading="loading"
      @update:options="getRunningtext(opsitable)">
      <template v-slot:item.icon="{ item }">
        <div>
          <v-img max-height="36" max-width="36" :src="item.icon"/>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="ma-4 text-xs-left">
          <v-chip
            :color="colorStatus(item.status)"
            small
            text-color="white">
            {{ statusText(item.status) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RunningText',
  data: ()=>({
    search: '',
    tab: '',
    newdata: {
      label:'',
      info:'',
      status:0
    },
    titledialog: 'edit',
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    state: 0,
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Label',
        value: 'label'
      },
      {
        text: 'Info',
        value: 'info'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
  },
  computed: {
    ...mapState('RunningTextStore',['loading','dialog','runningtext','totalrunningtext'])
  },
  methods: {
    ...mapActions('RunningTextStore',['setdialog','getRunningtext','setloading','addRunningtext','editRunningtext','deleteRunningtext']),
    simpan(){
      if(this.newdata.status){
        this.newdata.status=1
      }else{
        this.newdata.status=0;
      }
      if(this.state===1){
        this.addRunningtext(this.newdata)
      }else{
        this.editRunningtext(this.newdata)
      }
    },
    colorStatus(val){
      if(val===0){
        return "red";
      }
      if(val===1){
        return "green";
      }
    },
    statusText(val){
      if(val===0){
        return "Non-Aktif";
      }
      if(val===1){
        return "Aktif";
      }
    },
    openaddnew(){
      this.state=1;
      this.newdata={}
      this.titledialog="Tambah"
      this.setdialog(true)
    },
    openedit(data){
      this.state=0;
      this.newdata=data;
      this.titledialog="Edit"
      this.setdialog(true)
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ?', { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteRunningtext(this.delete_id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
