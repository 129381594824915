import { KycService } from '@/services/kyc.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  kyc: [],
  totalkyc: 0
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getKyc({dispatch, commit },opt) {
    commit('setloading',true)
    KycService.getAllKyc(opt).then(value => {
      commit('setdata',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  updateKyc({ dispatch,commit },opt) {
    commit('setloading',true)
    KycService.updateKyc(opt).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.kyc = []
    state.kyc = s.data.content
    state.totalkyc = s.data.totalElements
    state.loading = false
    state.overlay = false
  }
}

export const KycStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
