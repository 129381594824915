<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)">
    <v-tabs  v-model="tab">
      <v-tab>Menu</v-tab>
      <v-tab>Group</v-tab>
      <v-tab-item>
        <MenuAppPanel/>
      </v-tab-item>
      <v-tab-item>
          <MenuGroup/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import MenuGroup from '@/views/MenuGroup'
import MenuAppPanel from '@/components/MenuAppPanel'
export default {
  name: 'MenuApplikasi',
  components: { MenuAppPanel, MenuGroup },
  data: () => ({
    tab: null
  })
}
</script>

<style scoped>

</style>
