<template>
  <div class="mx-auto align-content-center text-center ma-4" v-if="show">
    <h4>ORDER SUKSES</h4>
    <sweetalert-icon icon="success" />
    <p>OrderID: {{paymentresponse.data.data.id}}</p>
    <h4>Jumlah Bayar: {{formatAngka(paymentresponse.data.data.total)}}</h4>
    <p>{{paymentresponse.data.msg}}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Utils from '@/plugins/utils'

export default {
  name: 'OrderSukses',
  props: ['show'],
  computed: {
    ...mapState('IRSXStore', ['paymentresponse'])
  },
  methods: {
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
  }
}
</script>

<style scoped>

</style>
