<template>
  <v-navigation-drawer
    id="appDrawer"
    :mini-variant.sync="mini"
    fixed
    :dark="$vuetify.dark"
    app
    clipped
    v-model="authentication.drawer"
  >
      <v-list shaped>
        <template v-for="(item, i) in menus">
          <!--group with subitems-->
          <v-list-group
            v-if="item.items"
            :key="item.name"
            :group="item.group"
            :prepend-icon="item.icon"
            no-action="no-action"
          >
            <v-list slot="activator" ripple="ripple">
              <v-list-item>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <template v-for="(subItem, i) in item.items">
              <!--sub group-->
              <v-list-group
                v-if="subItem.items"
                :key="subItem.name"
                :group="subItem.group"
                sub-group="sub-group">

                <v-list slot="activator" ripple="ripple">
                  <v-list-item>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list-item
                  v-for="(grand, i) in subItem.children"
                  :key="i"
                  :to="genChildTarget(item, grand)"
                  :href="grand.href"
                  ripple="ripple"
                >
                  <v-list-item>
                    <v-list-item-title>{{ grand.title }}</v-list-item-title>
                  </v-list-item>
                </v-list-item>
              </v-list-group>
              <!--child item-->
              <v-list-item
                v-else
                :key="i"
                :to="genChildTarget(item, subItem)"
                :href="subItem.href"
                :disabled="subItem.disabled"
                :target="subItem.target"
                ripple="ripple"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ subItem.title }} </span>
                  </v-list-item-title>
                </v-list-item-content>
                 <v-badge class="white--text pa-0 circle-pill" v-if="subItem.badge" color="red" disabled="disabled">{{ subItem.badge }}</v-badge>
                <v-list-item-action v-if="subItem.action">
                  <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>
          <v-subheader v-else-if="item.header" :key="i">{{ item.header }}</v-subheader>
          <v-divider v-else-if="item.divider" :key="i"></v-divider>
          <!--top-level link-->
          <v-list-item
            v-else-if="cekrole(item)"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            ripple="ripple"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="item.name"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="cekrole(item)">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
<!--            <v-badge v-if="item.badge" class="white&#45;&#45;text pa-0 chip&#45;&#45;x-small" :color="item.color || 'primary'" disabled="disabled">{{ item.badge }}</v-badge>-->

            <v-list-item-action v-if="item.subAction">
              <v-icon class="success--text">{{ item.subAction }}</v-icon>
            </v-list-item-action>
             <v-badge color="accent"  class="caption accent lighten-2 white--text mr-4" v-else-if="item.badge" label="label" :content="totalticketsupport"></v-badge>
          </v-list-item>
        </template>
<!--        </v-list-item-group>-->
      </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from 'vuex'
import menu from '../api/menu'
export default {
  name: 'app-drawer',
  components: {

  },
  props: {
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    mini: false,
    logo: 'https://icon.irmastore.id/logo-irma-white.png',
    drawer: true,
    item: 1,
    menus: menu,
    scrollSettings: {
      maxScrollbarLength: 160
    }
  }),
  computed: {
    ...mapState({
      authentication: state => state.authentication
    }),
    ...mapState('TicketSupportStore', ['totalticketsupport']),
    computeGroupActive () {
      return true
    },
    sideToolbarColor () {
      return this.$vuetify.options.extra.sideNav
    },
    roleuser () {
      const usr = JSON.parse(localStorage.getItem('user'))
      console.log(usr)
      return 0
    }
  },
  created () {
    this.$on('APP_DRAWER_TOGGLED', () => {
      console.log('APP_DRAWER_TOGGLED')
      this.drawer = !this.drawer
    })
  },
  methods: {
    cekroleheader (item) {
      let hasil = true
      if (item.header === 'Administrator') {
        if (this.roleuser === 0) {
          hasil = false
        }
      }
      return hasil
    },
    cekrole (item) {
      let hasil = true
      if (item.name === 'kasproagen' || item.name === 'mitraserver') {
        if (this.roleuser === 0) {
          hasil = false
        }
      }
      return hasil
    },
    genChildTarget (item, subItem) {
      if (subItem.href) return
      if (subItem.component) {
        return {
          name: subItem.component
        }
      }
      return { name: `${item.group}/${subItem.name}` }
    }
  }
}
</script>

<style lang="stylus">
#appDrawer {
  overflow: hidden;

  .drawer-menu--scroll {
    height: calc(100vh - 48px);
    overflow: auto;
  }

  .logox {
    position: relative !important;
    margin: auto;
  }
}
</style>
