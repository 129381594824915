import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export const UserService = {
  getUsers,
  getUserDetail,
  deleteRoleUser,
  addRoleUser,
  disableUser,
  enableUser,
  disablewallet,
  enablewallet,
  editUser,
  gantipassword
}
function gantipassword (customer) {
  return fetch(API_URL + '/api/v1/user/gantipassword', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    body: JSON.stringify(customer)
  }).then(handleResponse)
}

function editUser (postdata) {
  return fetch(API_URL + '/api/admin/v1/user', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function enablewallet (iduser) {
  return fetch(API_URL + '/api/admin/v1/users/'+iduser+'/wallet/enable', {
    method: 'PATCH',
    body: JSON.stringify({ iduser }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function disablewallet (iduser) {
  return fetch(API_URL + '/api/admin/v1/users/'+iduser+'/wallet/disable', {
    method: 'PATCH',
    body: JSON.stringify({ iduser }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function enableUser (iduser) {
  return fetch(API_URL + '/api/admin/v1/users/'+iduser+'/enable', {
    method: 'PATCH',
    body: JSON.stringify({ iduser }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function disableUser (id) {
  return fetch(API_URL + '/api/admin/v1/users/'+id+'/disable', {
    method: 'PATCH',
    body: JSON.stringify({ id }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getUserDetail (userid) {
  return fetch(API_URL + '/api/admin/v1/user/'+userid, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function getUsers (opt) {

  var sort="id"
  var sorder="DESC"
  if(opt.sortBy){
    console.log(opt.sortBy)
    sort=opt.sortBy;
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  if(!opt.search){
    opt.search=''
  }

  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/users?page='+page+'&search='+opt.search+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function addRoleUser (postdata) {
  return fetch(API_URL + '/api/admin/v1/user/role/'+postdata.userid+'/'+postdata.roleid, {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function deleteRoleUser (postdata) {
  return fetch(API_URL + '/api/admin/v1/user/role/'+postdata.userid+'/'+postdata.roleid, {
    method: 'DELETE',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
