<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-col cols="12" md="12 ">
        <v-text-field label="Nama Lengkap" v-model="value.namalengkap"></v-text-field>
        <v-text-field label="Alamat" v-model="value.alamat"></v-text-field>
        <v-text-field label="Provinsi" v-model="value.provinsi"></v-text-field>
        <v-text-field label="Kota" v-model="value.kota"></v-text-field>
        <v-text-field label="Kota" v-model="value.kecamatan"></v-text-field>
        <v-text-field label="No KTP" v-model="value.noktp"></v-text-field>
        <v-text-field label="Tgl Lahir" v-model="value.tgllahir"></v-text-field>
        <v-switch
          flat icon class="ml-4"
          inset
          v-model="value.status"
          label="Status"/>
      </v-col>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'DataUmum',
  props: ['value'],
}
</script>

<style scoped>

</style>
