import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl
export const ApplikasiService = {
  getAplikasi,
  addAplikasi,
  editaplikasi,
  deleteAlikasi
}

function getAplikasi (opt) {
  var sort="id"
  var sorder="DESC"
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/aplikasi?page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function addAplikasi (postdata) {
  return fetch(API_URL + '/api/admin/v1/aplikasi', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function editaplikasi (postdata) {
  return fetch(API_URL + '/api/admin/v1/aplikasi', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function deleteAlikasi (postdata) {
  return fetch(API_URL + '/api/admin/v1/aplikasi/'+postdata, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
