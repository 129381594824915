<template>
    <div>
      <v-dialog v-model="dialogorder" persistent max-width="500">
        <v-card>
          <v-toolbar color="primary" dark flat dense cad>
            <v-toolbar-title class="subheading">DetailOrder</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="setdialogorder(false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="text-xs-left">
            <div class="mt-4"/>
            <v-list>
              <v-list-item-group>
                <template v-for="(order, index) in detailorder">
                  <v-list-item ripple append :key="index">
                    <v-list-item-avatar><v-img :src="order.preview"></v-img> </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title style="cursor: pointer" v-text="order.nama"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn v-if="order.status>0"  icon @click="openpage(order.asset)"><v-icon>mdi-download</v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    :key="`${index}-divider`"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text dark @click="setdialogorder(false)" color="red">Tutup</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="orders"
        class="elevation-0"
        item-key="id"
        :options.sync="opsitable"
        :server-items-length="totalorder"
        :loading="loading"
        @update:options="getOrders(opsitable)">
        <template v-slot:item.icon="{ item }">
          <div>
            <v-img max-height="36" max-width="36" :src="item.icon"/>
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <div class="ma-4 text-xs-left">
            <v-chip
              :color="colorStatus(item.status)"
              small
              text-color="white">
              {{ statusText(item.status) }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.createat="{ item }">
          <div class="">
            {{formatDate(item.createat)}}
          </div>
        </template>
        <template v-slot:item.amount="{ item }">
          <div class="text-right">
              {{formatAngka(item.amount)}}
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div class="ma-4 text-xs-left">
            <v-btn x-small fab text @click.native="openedit(item)" class="primary">
              <v-icon small>mdi-square-edit-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'
import moment from 'moment'

export default {
  name: 'Orders',
  data: () => ({
    search: '',
    orderid: '',
    titledialog: 'edit',
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    headers: [
      {
        text: 'OrderId',
        value: 'id'
      },
      {
        text: 'Jumlah',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Waktu Order',
        value: 'createat'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
    ],
  }),
  mounted () {
  },
  computed: {
    ...mapState('IRSXStore',['orders','totalorder','loading','dialogorder','detailorder'])
  },
  methods: {
    ...mapActions('IRSXStore',['getOrders','setdialogorder','getOrderdetail']),
    openpage(url){
      window.open(url, '_blank');
    },
    colorStatus(val){
      if(val===0){
        return "blue";
      }
      if(val===1){
        return "green";
      }
    },
    openedit(item){
      this.orderid=item.id;
      this.getOrderdetail(item.id)
      this.setdialogorder(true)
    },
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    statusText(val){
      if(val===0){
        return "Menunggu Pembayaran";
      }
      if(val===1){
        return "Terbayar";
      }
    },
  }
}
</script>

<style scoped>

</style>
