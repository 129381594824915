<template>
  <div class="ma-4">
    <DialogImageShare/>
      <v-layout wrap>
        <v-col cols="12"  xs="12" sm="2" md="2" lg="2">
          <div class="text-center">
            <div class="logoapp" @click="openfile">
              <input
                hidden
                type="file"
                accept="image/*"
                ref="logouploadupload"
                v-on:change="upload($event.target.files)"
              />
              <div  v-if="loadingupload" class="imglogo">
                <v-progress-circular
                  :size="50"
                  :width="2"
                  class="imgupload"
                  color="red"
                  indeterminate
                ></v-progress-circular>
                <div class="upload-text">
                  Uploading....
                </div>
              </div>
              <img alt="oke" class="imglogo" v-if="!loadingupload" :src="appsetting.applogo"/>
            </div>
            <div class="mt-4"><v-btn  v-if="!loadingupload" text @click.native="openfile" class="primary"><v-icon class="mr-2">mdi-cloud-upload</v-icon>Ganti</v-btn></div>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="5" lg="5">
          <v-text-field type="text" label="AppName" v-model="appsetting.appname"></v-text-field>
          <v-text-field type="text" label="AppTagline" v-model="appsetting.apptagline"></v-text-field>
          <v-select
            label="Sender OTP"
            item-text="nama"
            item-value="id"
            v-model="appsetting.senderotp"
            :items="senderotpstipe"
          ></v-select>
          <v-row class="ml-1">
            <v-select
              label="Register Online"
              item-text="nama"
              item-value="id"
              v-model="appsetting.regonline"
              :items="ijinkanregon"
            ></v-select>
            <v-text-field type="number" label="Max Register 1 hari" v-model="appsetting.registerquota"></v-text-field>
          </v-row>
          <v-text-field type="text" label="Zopim Key" v-model="appsetting.zopimkey"></v-text-field>
          <v-row class="ml-1">
            <v-select
                label="Digipos Terminal"
                item-text="namaterminal"
                item-value="id"
                v-model="appsetting.digiposterminal"
                :items="terminalh2h"
            ></v-select>
            <v-select
                label="iSimple Terminal"
                item-text="namaterminal"
                item-value="id"
                v-model="appsetting.isimpleterminal"
                :items="terminalh2h"
            ></v-select>
          </v-row>
          <v-row class="ml-1">
            <v-select
                label="Rita Terminal"
                item-text="namaterminal"
                item-value="id"
                v-model="appsetting.ritaterminal"
                :items="terminalh2h"
            ></v-select>
            <v-select
                label="SiDompul Terminal"
                item-text="namaterminal"
                item-value="id"
                v-model="appsetting.sidompulappterminal"
                :items="terminalh2h"
            ></v-select>
          </v-row>
          <v-row class="ml-1">
            <v-select
              label="SRIS Terminal"
              item-text="namaterminal"
              item-value="id"
              v-model="appsetting.sristerminal"
              :items="terminalh2h"
            ></v-select>
          </v-row>
          <v-row class="ml-1">
            <div>
              <div><span>Primary Color</span></div>
              <input id="primary-color-darkx" class="colorbox" type="color" v-model="appsetting.primarycolor"/>
            </div>
            <div class="ml-2">
              <div><span>Accent Color</span></div>
              <input id="accent-color-darkx" class="colorbox" type="color" v-model="appsetting.accentcolor"/>
            </div>
          </v-row>
        </v-col>
        <v-col cols="12"  xs="12" sm="5" md="5" lg="5">
          <v-text-field @click:append="setdialogimageshare(true)" prepend-icon="mdi-image" append-icon="mdi-upload" type="text" label="ImageReferral" v-model="appsetting.imagesharerefferal"></v-text-field>
          <v-text-field prepend-icon="mdi-facebook" type="text" label="Facebook" v-model="appsetting.facebook"></v-text-field>
          <v-text-field prepend-icon="mdi-instagram" type="text" label="Instagram" v-model="appsetting.instagram"></v-text-field>
          <v-text-field prepend-icon="mdi-web" type="text" label="Syarat Ketentuan Url" v-model="appsetting.syaratdanketentuan"></v-text-field>
          <v-text-field prepend-icon="mdi-web" type="text" label="Privacy Policy Url" v-model="appsetting.privacypolicy"></v-text-field>
          <v-text-field prepend-icon="mdi-apple" type="text" label="Apple StoreID" v-model="appsetting.iosappstoreid"></v-text-field>
          <v-text-field prepend-icon="mdi-apple" type="text" label="Apple Store Link" v-model="appsetting.appstorelink"></v-text-field>
          <v-text-field prepend-icon="mdi-web" type="text" label="Domain DynamicLink" v-model="appsetting.dynamiclinkdomain"></v-text-field>
        </v-col>
        <v-divider class="mt-4 mb-4"></v-divider>
      </v-layout>
    <v-divider class="mt-4"/>
    <v-row class="mt-2">
      <v-spacer></v-spacer>
      <v-btn @click="simpan" :loading="loading" color="primary"><v-icon>mdi-content-save</v-icon>Simpan</v-btn>
    </v-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { serviceUpload } from '@/services/serviceupload'
import DialogImageShare from '@/components/DialogImageShare'

export default {
  name: 'GeneralSetting',
  components: { DialogImageShare },
  data: () => ({
    loadingupload:false,
    done:false,
    ijinkanregon: [
      {
        id:0,
        nama: 'Tidak'
      },
      {
        id:1,
        nama: 'Ya'
      }
    ],
    senderotpstipe:[
      {
        id:0,
        nama:'LocalSender'
      },
      {
        id:1,
        nama:'IRMA'
      },
      {
        id:2,
        nama:'WANode'
      }
    ],
  }),
  mounted () {
    this.getTerminalH2h()
    this.setImage('')
  },
  computed: {
    ...mapState('SettingStore',['loading','dialog','appsetting','image']),
    ...mapState('ProductStore',['terminalh2h'])
  },
  methods: {
    ...mapActions('SettingStore',['getSetting','updateSetting','setImage', 'setdialogimageshare']),
    ...mapActions('ProductStore',['getTerminalH2h','getPriceplan']),
    simpan(){
      if(this.image!==''){
        this.appsetting.applogo=this.image
      }
      this.updateSetting(this.appsetting)
    },
    openfile () {
      this.$refs.logouploadupload.click()
    },
    showPreview: async function (file) {
      var reader = new FileReader()
      if (file) {
        this.loadingupload = true
        console.log('uploading')
        serviceUpload.newuploadimage(file)
          .then(response => {
            this.loadingupload = false
            console.log(response.data)
            this.done = true
            window.getApp.$emit('DONE_SET_IMAGE_LOGO', response.data)
          })
          .catch(error => {
            this.loadingupload = false
            this.done = false
            console.log('err upload')
            console.log(error)
          })
        reader.readAsDataURL(file)
        const result = await new Promise((resolve) => {
          reader.onload = function () {
            resolve(reader.result)
          }
        })
        this.src = result
        this.value = result
      }
    },
    upload: function (files) {
      this.showPreview(files[0])
    }
  }
}
</script>

<style scoped>
.colorbox {
  height: 50px;
}
.imglogo {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.logoapp {
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  height: 200px;
  border-width: 2px;
  border-color: #666666;
}
</style>
