<template>
  <div id="change_password">
    <v-container grid-list-xl fluid>
      <v-layout row wrap align-center justify-center>
        <v-flex lg3 md6 sm6>
          <v-card elevation="1">
            <v-toolbar color="primary" dark flat dense cad>
              <v-toolbar-title class="subheading">Ganti Password</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-xs-left">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-container pa-0>
                  <v-layout column>
                    <v-flex lg12 md6 sm12 pt-0 pb-0>
                      <v-text-field
                          class="padr"
                          type="password"
                          v-validate="'required|min:8'"
                          data-vv-name="password"
                          label="Password"
                          name="password"
                          v-model="password"
                      ></v-text-field>
                    </v-flex>
                    <v-flex lg12 pt-0 pb-0>
                      <v-text-field
                          class="padr"
                          v-validate="'required|min:8'"
                          data-vv-name="newpassword"
                          label="New Password"
                          name="newpassword"
                          type="password"
                          ref="newpassword"
                          v-model="newpassword"
                      ></v-text-field>
                    </v-flex>
                    <v-flex lg12 pt-0 pb-0>
                      <v-text-field
                          class="padr"
                          type="password"
                          v-validate="'required|confirmed:newpassword|min:8'"
                          label="Confm Password"
                          name="confmnewpassword"
                          data-vv-name="confmnewpassword"
                          v-model="confmnewpassword"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  elevation="0"
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  @click="simpanForm()"
              >Ganti</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GantiPassword',
  data: () => ({
    Rules: [v => !!v || 'Please fill value'],
    loading: false,
    valid: false,
    password: '',
    newpassword: '',
    confmnewpassword: ''
  }),
  computed: {},
  methods: {
    ...mapActions({
      gantipassword: 'UserStore/gantipassword'
    }),
    closeform () {
      console.log('gagal')
    },
    simpanForm () {
      const { password, newpassword, confmnewpassword } = this
      var pass = {
        a: password,
        b: newpassword,
        c: confmnewpassword
      }
      this.gantipassword({ pass })
    }
  }

}
</script>

<style scoped>

</style>
