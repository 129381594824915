<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="4">
        <div class="borderkotak">
          <div class="ma-2 justify-center text-center"><h3>Kategori Produk</h3></div>
          <v-list shaped>
            <v-list-item-group>
              <template v-for="(item, index) in categories">
                <v-list-item ripple append  active-class="deep-orange--text" :key="index">
                  <template>
                    <v-list-item-content @click="onseleccat(item)">
                      <v-list-item-title style="cursor: pointer" v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action> <v-chip >
                      {{item.jumlah}}
                    </v-chip></v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="10" md="10" sm="8">
        <div class="ma-0 borderkotak">
          <div class="productlist">
            <v-layout row wrap class="mt-2 pa-4">
              <v-flex lg2 sm6 md4 xs12 v-for="(item, index) in products"  :key="index">
                <v-card class="ma-2 border_all" elevation="1">
                    <v-img @click="godetail(item)" height="150" max-width="100%" :src="item.preview"></v-img>
                    <v-card-text  class="align-center align-content-center mt-3 text-center center-pill align-content-center">
                      <div v-if="item.price===0" class="tagpromo"><span>Free</span></div>
                      <div class="text-center">{{item.productname}}</div>
                      <div v-if="item.price===0"><v-chip label small class="" color="green" text-color="white">
                        Free
                      </v-chip></div>
                      <div v-if="item.price>0" class="text-center"><h3 class="deep-orange--text">Rp {{formatAngka(item.price)}}</h3></div>
                      <div>{{item.nama}}</div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions style="cursor: pointer">
                      <v-btn text dark @click="tambahcart(item,index)" :disabled="item.incart" block class="deep-orange lighten-1"><v-icon>mdi-cart-plus</v-icon> Beli</v-btn>
                    </v-card-actions>
                  </v-card>
              </v-flex>
            </v-layout>
          </div>
        </div>
          <div class="productlist">
            <v-row align="center" justify="center" style="height: 200px;" v-if="products.length===0" class="fill-height">
             <v-card elevation="0" height="10%" class="">
               <sweetalert-icon icon="info"/>
               Produk tidak ditemukan
             </v-card>
            </v-row>
          </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'

export default {
  name: 'Market',
  data: () => ({
   // selectedcategory: null
  }),
  mounted () {
    this.getCategories();
    this.getProducts();
  },
  computed: {
    ...mapState('IRSXStore', ['categories','products','selectedcategory'])
  },
  methods: {
    ...mapActions('IRSXStore', ['getProducts','getCategories','addtochart','clearchart','setincart', 'setselectedcategory','setselectedproduct']),
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    onseleccat(item){
      if(this.selectedcategory===null){
        this.setselectedcategory(item)
      }else{
        if(item.id === this.selectedcategory.id){
          this.setselectedcategory(null)
        }else{
          this.setselectedcategory(item)
        }
      }
      if (this.selectedcategory!==null){
        this.getProducts({categoryid:this.selectedcategory.id});
      }else{
        this.getProducts();
      }
    },
    godetail(prod){
      this.setselectedproduct(prod)
      this.$router.push('irsxstore/product/detail')
    },
    tambahcart(item){
      item.incart=true
      this.addtochart(item)
    }
  }
}
</script>

<style scoped>
.borderkotak {
  border: thin solid rgba(0,0,0,.12)
}
.tagpromo {
  position: absolute;
  left: -2px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 80px;
  height: 80px;
  text-align: right;
}

.tagpromo span {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  line-height: 17px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 83px;
  display: block;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  background: #79A70A;
  background: linear-gradient(green 0%, green 100%);
  /* background: linear-gradient(#3bafda 0%, #3bafda 100%); */
  position: absolute;
  top: 9px;
  left: -24px;
}

.tagpromo span:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid green;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid green;
}
.tagpromo span:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid green;
  border-bottom: 3px solid transparent;
  border-top: 3px solid green;
}
</style>
