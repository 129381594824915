<template>
  <div>
    <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)">
      <v-tabs  v-model="tab" >
        <v-tab>SiDompul</v-tab>
        <v-tab>RITA</v-tab>
        <v-tab-item>
          <SidompulMain/>
        </v-tab-item>
        <v-tab-item>
          <RitaPanale/>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import RitaPanale from "@/components/moduleoperator/RitaPanel";
import SidompulMain from "@/components/moduleoperator/SidompulMain";
export default {
  name: "ModuleOperatorPage",
  components: {SidompulMain, RitaPanale},
  data: () => ({
    tab: null
  }),
}
</script>

<style scoped>

</style>
