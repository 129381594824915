import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export  const MenuGroupServices = {
  getAllMenuGroup,
  addMenuGroup,
  deleteMenuGroup,
  editMenuGroup
}


function getAllMenuGroup (opt) {
  var sort="id"
  var sorder="ASC";
  if(opt.search==null){
    opt.search="";
  }
  if(opt.sortBy){
    if(opt.sortBy[0]){
      sort=opt.sortBy[0]
    }
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/menugroup?search='+opt.search+'&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function addMenuGroup (postdata) {
  return fetch(API_URL + '/api/admin/v1/menugroup', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function editMenuGroup (postdata) {
  return fetch(API_URL + '/api/admin/v1/menugroup', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function deleteMenuGroup (id) {
  return fetch(API_URL + '/api/admin/v1/menugroup/'+id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
