<template>
  <v-layout>
    <v-col xs="12" sm="12" md="4" lg="4">
      <v-text-field  dense outlined label="Id Agen" v-model="userdetail.masterreseller.idreseller" readonly></v-text-field>
      <v-text-field  dense outlined label="Id Upline" v-model="userdetail.masterreseller.idupline" readonly></v-text-field>
      <v-text-field  dense outlined label="Nama Agen" v-model="userdetail.masterreseller.namareseller" readonly></v-text-field>
      <v-text-field  dense outlined label="Alamat" v-model="userdetail.masterreseller.alamatreseller" readonly></v-text-field>
      <v-text-field  dense outlined label="Domisili" v-model="userdetail.masterreseller.idareadomisili" readonly></v-text-field>
      <v-text-field  dense outlined label="PricePlan" v-model="userdetail.masterreseller.patokanhargajual" readonly></v-text-field>
      <v-text-field  dense outlined label="Saldo" v-model="userdetail.masterreseller.saldo" readonly></v-text-field>
      <v-text-field  dense outlined label="Komisi" v-model="userdetail.masterreseller.komisi" readonly></v-text-field>
      <v-text-field  dense outlined  label="Poin" v-model="userdetail.masterreseller.poin" readonly></v-text-field>
    </v-col>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Agen',
  data: () => ({

  }),
  computed: {
    ...mapState('UserStore',['dialoguserdetail','userdetail'])
  },
}
</script>

<style scoped>

</style>
