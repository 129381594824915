<template>
  <v-container class="fill-height back" fluid>
    <v-app-bar clipped-left app flat dark color="transparent">
      <img @click="home" class="logo" height="36" src="../assets/irswhite.png" alt="logo"/>
      <v-spacer/>
      <v-toolbar-items>
        <v-btn text>Fitur</v-btn>
        <v-btn @click="login" text>Login</v-btn>
        <v-btn @click="register" text>Register</v-btn>
      </v-toolbar-items>
    </v-app-bar>
      <v-layout align-center justify-center>
        <v-flex xs12 sm6 md6 lg6>
          <v-container class="text-xs-center">
            <v-card >
              <v-layout row wrap>
                <div class="primary layout align-center column hidden-sm-and-down">
                  <v-img
                    class="column align-center"
                    src="../assets/login.png"
                    alt="irma"
                    max-height="300"
                    width="300"
                  />
                </div>
                <v-flex xs12 sm12 md12 lg6>
                      <h1 class="text-center primary--text  mt-5">Reset Password</h1>
                  <v-container>
                    <v-card-text>
                      <v-form @submit.prevent="handleSubmit()">
                        <v-text-field
                                v-model="email"
                                name="email"
                                :rules="emailRules"
                                required
                                type="email"
                                label="Email"
                        ></v-text-field>
                        <v-card-actions>
                          <v-btn
                                  block
                                  class="primary"
                                  @click.native="handleSubmit()"
                                  :loading="loading"
                          >Reset my Password</v-btn>
                        </v-card-actions>
                      </v-form>
                      <div style="height:20px"></div>
                      <a href="#" class="magneta" @click.prevent="loginform()">Login to Accounnt</a>
                      <div style="height:20px"></div>

                    </v-card-text>
                  </v-container>
                </v-flex>
              </v-layout>
            </v-card>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '../router/'
export default {
  name: 'resetpassPage',
  data () {
    return {
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        // eslint-disable-next-line no-useless-escape
        v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      password: '',
      passwordRules: [v => !!v || 'Password is required'],
      submitted: false,
      items: [
        {
          src: '/static/banner/banner0.jpg'
        },
        {
          src: '/static/banner/banner2.jpg'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['status', 'loading'])
  },
  created () {

  },
  methods: {
    ...mapActions('authentication', ['resetpass', 'logout']),
    handleSubmit () {
      this.submitted = true
      const { email } = this
      if (email) {
        this.resetpass({ email })
      }
    },
    loginform () {
      router.push('/login')
    },
    register () {
      this.$router.push('/register')
    },
    forgot () {
      this.$router.push('/forgot')
    },
    home(){
      this.$router.push('/')
    },
    login(){
      this.$router.push("/login")
    },
  }
}
</script>

<style lang="stylus" scoped>
  .back {
    background-image: linear-gradient(to right, rgba(5, 11, 31, 0.8), rgba(5, 11, 31, 0.8)), url("https://www.paper.id/blog/wp-content/uploads/2018/11/digital-payment-India-1280x720.jpg");
    background-size: cover;
  }
.banner {
  height: 355px;
}
a {
  text-decoration: none;
}
</style>
