<template>
  <div>
    <v-layout row wrap>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-account-cash</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">Total VA</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(totalva)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-storefront</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">AlfaMart</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(totalalfamart)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-qrcode</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">QRIS</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(totalqr)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex lg3  sm6 xs12>
        <v-card class="ma-3" elevation="0" style="border: thin solid rgba(0,0,0,.12)">
          <v-list-item>
            <v-sheet class="mt-n12" color="green" width="50" height="50" elevation="1">
              <v-icon dark large class="icondash">mdi-wallet</v-icon>
            </v-sheet>
            <v-list-item-content>
              <div class="text-right mb-2">Total</div>
              <v-list-item-title class="headline mb-1 text-right">{{formatAngka(totalqr+totalva+totalalfamart)}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)">
      <v-tabs  v-model="tab" >
        <v-tab><v-badge color="green" :content="totalva">Virtual Account</v-badge></v-tab>
        <v-tab><v-badge color="green" :content="totalalfamart">AlfaMart</v-badge></v-tab>
        <v-tab><v-badge color="green" :content="totalqr">QRis</v-badge></v-tab>
        <v-tab><v-icon>mdi-cog</v-icon>Config</v-tab>
        <v-tab-item>
          <VirtualAccount/>
        </v-tab-item>
        <v-tab-item>
          <AlfamartPayment/>
        </v-tab-item>
        <v-tab-item>
          <QrPayment/>
        </v-tab-item>
        <v-tab-item>
          <PaymentGatewaySetting/>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>

</template>

<script>
import VirtualAccount from '@/components/paymentgateway/VirtualAccount'
import AlfamartPayment from '@/components/paymentgateway/AlfamartPayment'
import QrPayment from '@/components/paymentgateway/QrPayment'
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'
import PaymentGatewaySetting from '@/components/PaymentGatewaySetting'
export default {
  name: 'PaymentGateway',
  components: { PaymentGatewaySetting, QrPayment, AlfamartPayment, VirtualAccount },
  data: () => ({
    tab: null
  }),
  mounted () {
    this.getListAlfaMart();
    this.getListVa();
   // this.getListQrpayment();
  },
  computed: {
    ...mapState('PaymentGatewayStore',['loading','dialog','va','totalva','totalqr','totalalfamart']),
  },
  methods: {
    ...mapActions('PaymentGatewayStore',['setdialog','getListVa','getListAlfaMart','getListQrpayment']),
    formatAngka(val){
      return Utils.formatAngka(val)
    },
  }
}
</script>

<style scoped>
.caro {
  height: 265px;
}
.icondash {
  margin-left: 6px;
  margin-top: 5px;
}
</style>

