import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export const PaymentGatewayService = {
  getListva,
  getListAlfamart,
  getListQr,
  updateVA,
  updateAlfacode
}

function updateVA (postdata) {
  return fetch(API_URL + '/api/admin/v1/paymentgateway/va', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function updateAlfacode (postdata) {
  return fetch(API_URL + '/api/admin/v1/paymentgateway/alfacode', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}


function getListva (opt) {
  var sort="id"
  var sorder="DESC"
  if(typeof opt == 'undefined'){
    opt={
      search: '',
      itemsPerPage: 10,
      page: 1,
      sortBy: ["id"],
    }
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  let status=''
  if(opt.status===-1){
    status=''
  }else {
    status=opt.status
  }
  if(typeof opt.awal === 'undefined'){
    opt.awal=new Date().toISOString().substr(0, 10)
    opt.akhir=new Date().toISOString().substr(0, 10)
    status=''
  }
  return fetch(API_URL + '/api/admin/v1/paymentgateway/va?status='+status+'&search=' + opt.search + '&awal=' + opt.awal + '&akhir=' + opt.akhir + '&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getListAlfamart (opt) {
  var sort="id"
  var sorder="DESC"
  if(typeof opt == 'undefined'){
    opt={
      search: '',
      itemsPerPage: 10,
      page: 1,
      sortBy: ["id"],
    }
  }
  if(opt.sortBy){
    console.log(opt.sortBy)
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  let status=''
  if(opt.status===-1){
    status=''
  }else {
    status=opt.status
  }
  if(typeof opt.awal === 'undefined'){
    opt.awal=new Date().toISOString().substr(0, 10)
    opt.akhir=new Date().toISOString().substr(0, 10)
    status=''
  }
  return fetch(API_URL + '/api/admin/v1/paymentgateway/alfacode?status='+status+'&search=' + opt.search + '&awal=' + opt.awal + '&akhir=' + opt.akhir + '&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getListQr (opt) {
  var sort="id"
  var sorder="DESC"
  if(typeof opt == 'undefined'){
    opt={
      search: '',
      itemsPerPage: 10,
      page: 1,
      sortBy: ["id"],
    }
  }
  if(opt.sortBy){
    console.log(opt.sortBy)
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let page=opt.page-1;
  let sorting=sort+","+sorder
  let status=''
  if(opt.status===-1){
    status=''
  }else {
    status=opt.status
  }
  if(typeof opt.awal === 'undefined'){
    opt.awal=new Date().toISOString().substr(0, 10)
    opt.akhir=new Date().toISOString().substr(0, 10)
    status=''
  }
  return fetch(API_URL + '/api/admin/v1/paymentgateway/qr?status='+status+'&search=' + opt.search + '&awal=' + opt.awal + '&akhir=' + opt.akhir + '&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
