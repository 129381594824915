<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12)" elevation="0">
    <v-dialog scrollable v-model="dialog" persistent width="500">
      <v-card>
        <v-toolbar color="primary" dark flat dense cad>
          <v-toolbar-title class="subheading">{{titledialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="setdialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-left">
          <v-form ref="form" lazy-validation>
            <v-container class="mt-4">
              <v-layout fluid row wrap>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Brand" readonly required v-model="newdata.brand"></v-text-field>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Label" required v-model="newdata.label"></v-text-field>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field label="Value" required v-model="newdata.value"></v-text-field>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-2">
                  <v-text-field type="number" label="Posisi" required v-model="newdata.posisi"></v-text-field>
                </v-flex>
                <v-flex lg6 sm12 xs12 pa-20 class="pl-0">
                  <v-switch
                    flat icon class="ml-4"
                    inset
                    v-model="newdata.tampil"
                    label="Tampil"/>

                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark @click="setdialog(false)" color="red">Tutup</v-btn>
          <v-btn text dark @click="simpan" :loading="loading" color="green">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="category"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalcategory"
      :loading="loading"
      @update:options="getCategory(opsitable)">
      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-select style="width: 50px;!important;" class="mt-4"
                    single-line item-text="nama"
                    @change="getCategory(opsitable)"
                    filled rounded dense
                    v-model="opsitable.brand" item-value="id" :items="brand"  label="Brand">

          </v-select>
          <v-spacer></v-spacer>
          <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getCategory(opsitable)">
            <v-icon dark>mdi-refresh</v-icon>Refresh
          </v-btn>
          <v-btn small dark elevation="0" class="ml-2" color="green" @click.native="syncConfm(opsitable)">
            <v-icon color="white" dark>mdi-refresh</v-icon>Sync Category
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.tampil="{ item }">
        <div class="ma-1 text-xs-left">
          <v-chip
            :color="colorStatus(item.tampil)"
            small
            text-color="white">
            {{ statusText(item.tampil) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row>
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn icon x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "SidompulPanel",
  data: ()=>({
    search:"",
    opsitable: {
      search:'',
      brand:"",
      itemsPerPage: 10,
      page:1,
      sortBy: ["categorygroup"],
    },
    newdata:{
      "id": 0,
      "brand": "",
      "categorygroup": "",
      "label": "",
      "value": "",
      "posisi": 0,
      "tampil": 1
    },
    titledialog: "Edit",
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Brand',
        value: 'brand'
      },
      {
        text: 'Group',
        value: 'categorygroup'
      },
      {
        text: 'Label',
        value: 'label'
      },
      {
        text: 'Value',
        value: 'value'
      },
      {
        text: 'Posisi',
        value: 'posisi'
      },
      {
        text: 'Tampil',
        value: 'tampil'
      },
      {
        text: 'Aksi',
        value: 'action'
      },
  ]
  }),
  mounted () {},
  computed: {
    ...mapState('sidompulStore',['loading','dialog','category','totalcategory','brand'])
  },
  methods: {
    ...mapActions('sidompulStore',['setdialog','setloading','getCategory','updateCategory','deleteCategory','syncCategory']),
    colorStatus(val){
      if(val===0 || val===false){
        return "red";
      }
      if(val===1 || val===true){
        return "green";
      }
    },
    statusText(val){
      if(val===0 || val===false){
        return "Tidak";
      }
      if(val===1 || val===true){
        return "Ya";
      }
    },
    openedit(data){
      console.log(data)
      this.newdata=data;
      this.titledialog="Edit "+data.label;
      this.setdialog(true);
    },
    syncConfm(){
      this.$confirm('Yakin akan Tarik Category dari Sidompul App ? ', { color: 'green',title:'Sync',icon:'fa-success' }).then(res => {
        if (res) {
          this.syncCategory(this.opsitable.brand)
        }
      })
    },
    deleteconfm(item){
      this.delete_id = item.id
      this.$confirm('Yakin akan hapus ? '+item.label, { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteCategory(item)
        }
      })
    },
    simpan(){
      if(this.newdata.tampil){
        this.newdata.tampil=1
      }else{
        this.newdata.tampil=0;
      }
      this.updateCategory(this.newdata);
    }
  }
}
</script>

<style scoped>

</style>
