
import {SidompulServices} from "@/services/sidompul.service";


const state = {
  loading: false,
  dialog: false,
  overlay: false,
  icon:'',
  category: [],
  totalcategory:0,
  totalproducts:0,
  products: [        {
    "productCategory": "AKRAB",
    "productCode": "AKRAB10",
    "productName": "Paket Akrab S, 30hr",
    "normalPrice": "62000",
    "dompulPrice": "59000",
    "productImage": "https://d17e22l2uh4h4n.cloudfront.net/tokoxl/2021-03/Content%20IMG_Sidompul-09.jpg"
  }],
  brand:[
    {id:"XL",nama:"XL"},
    {id:"AXIS",nama:"AXIS"}
  ],
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getProducts({dispatch, commit },brand) {
    commit('setloading',true)
    SidompulServices.getSidompulProducts(brand).then(value => {
      commit('setproducts',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getCategory({dispatch, commit },brand) {
    commit('setloading',true)
    localStorage.setItem('sidompulfilter',JSON.stringify(brand))
    SidompulServices.getAllCategory(brand).then(value => {
      commit('setdatacategory',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  getCategoryFull({dispatch, commit },brand) {
    commit('setloading',true)
    brand.itemsPerPage=-1;
    localStorage.setItem('sidompulfilter',JSON.stringify(brand))
    SidompulServices.getAllCategory(brand).then(value => {
      commit('setdatacategory',value)
      commit('setloading',false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  syncCategory({ dispatch,commit },brand) {
    commit('setloading',true)
    SidompulServices.fetchSidompulMenu(brand).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      let filter = localStorage.getItem('sidompulfilter');
      dispatch('getCategory',JSON.parse(filter));
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  deleteCategory({ dispatch,commit },postdata) {
    commit('setloading',true)
    SidompulServices.deleteCategory(postdata).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      let filter = localStorage.getItem('sidompulfilter');
      dispatch('getCategory',JSON.parse(filter));
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  updateCategory({ dispatch,commit },postdata) {
    commit('setloading',true)
    SidompulServices.updateCategory(postdata).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      let filter = localStorage.getItem('sidompulfilter');
      dispatch('getCategory',JSON.parse(filter));
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
  addCategory({ dispatch,commit },postdata) {
    commit('setloading',true)
    SidompulServices.addCategory(postdata).then(value => {
      commit('setloading',false)
      commit('setdialog',false)
      let filter = localStorage.getItem('sidompulfilter');
      dispatch('getCategory',JSON.parse(filter));
      dispatch('alert/success', value.msg, { root: true })
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading',false)
    })
  },
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdatacategory (state, s) {
    state.category = []
    state.category = s.data.content
    state.totalcategory = s.data.totalElements
    state.loading = false
    state.overlay = false
  },
  setproducts (state, s) {
    state.products = []
    state.products = s.data
    state.totalproducts=s.data.length;
    state.loading = false
    state.overlay = false
  },
}

export const sidompulStore = {
  namespaced: true,
  state,
  actions,
  mutations
}

