
import { handleResponse } from './handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export const IRSXStoreService = {
  getProducts,
  getCategories,
  getAllOrder,
  orderRequest,
  getOrderDetail
}

function orderRequest (postdata) {
  return fetch(API_URL + '/api/admin/v1/store/doorder', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getOrderDetail (id) {
  return fetch(API_URL + '/api/admin/v1/store/orders/'+id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getAllOrder () {
  return fetch(API_URL + '/api/admin/v1/store/orders', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getProducts (opt) {
  if(typeof opt =='undefined'){
    opt={
      search:''
    }
  }

  if(typeof opt.pricemin === 'undefined'){
    opt.pricemin=0
  }
  if(typeof opt.pricemax === 'undefined'){
    opt.pricemax=1000000000
  }
  if(typeof opt.search === 'undefined'){
    opt.search=''
  }
  if(typeof opt.categoryid === 'undefined'){
    opt.categoryid=''
  }
  return fetch(API_URL + '/api/admin/v1/store/products?search='+opt.search +'&categoryid='+opt.categoryid+'&pricemin='+opt.pricemin+'&pricemax='+ opt.pricemax, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getCategories () {
  return fetch(API_URL + '/api/admin/v1/store/categories', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
